import Color from 'color'
import styled, { css } from 'styled-components'

import { Button } from '@src/components/Button'
import { SearchIconSVG } from '@src/components/SVGS/SearchIconSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.main<{ isTablePicker?: boolean }>`
  background-color: white;
  z-index: 20;
  position: fixed;
  height: auto;
  padding: 16px;
  border-radius: 4px;
  top: 64px;
  left: 32px;
  right: 32px;
  max-width: 300px;
  margin: auto;

  ${({ isTablePicker }) =>
    isTablePicker &&
    css`
      width: 80%;
      top: 128px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: ${breakpoints.tablet}px) {
        width: 40%;
      }
    `}

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 32px;
  }
`

export const Underlay = styled.div`
  position: fixed;
  display: flex;
  align-content: center;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: 15;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`

export const Title = styled.p`
  font-size: 16px;
  font-weight: 700;

  margin: 4px 0 0 0;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 18px;
    margin: 4px 0px;
  }
`

export const SubTitle = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin: 2px 0;
`

export const ButtonContainer = styled.div`
  margin-top: 16px;
`

export const StyledButton = styled(Button)`
  margin: 8px 0px;
`

export const LocationAlignmentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PostcodeInputContainer = styled.div`
  border: 2px solid #f0f0f0;
  padding: 0px;
  border-radius: 8px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
`

export const PostcodeInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  padding: 16px 2px 16px 16px;
  background-color: transparent;
`

export const SearchIcon = styled(SearchIconSVG)`
  width: 18px;
  height: 18px;
`

export const SearchButton = styled.button`
  flex-shrink: 0;
  border: none;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  padding: 0px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brandForeground};
  transition: 0.2s ease;
  transition-property: color, background-color;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) =>
      Color(theme.colors.brand).alpha(0.6).hexa()};
    color: ${({ theme }) =>
      Color(theme.colors.brandForeground).alpha(0.6).hexa()};
  }
`

export const StyledChoiceButton = styled(Button)`
  margin: 8px 0px;
`

export const CenteredText = styled.p`
  text-align: center;
`
