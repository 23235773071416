import { FormikProps } from 'formik'
import { toUpper } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CheckoutButtonWrapperMobile } from '@src/components/CheckoutModal/CheckoutButtonWrapperMobile'
import {
  CheckoutButton,
  FlexGrowForm,
  FlexGrowScrollContainer,
  FulfilmentNoteContainer,
} from '@src/components/CheckoutModal/FormElements.styles'
import { CheckoutLoyaltyCardSlider } from '@src/components/CustomerLoyaltyCard/CheckoutLoyaltyCardSlider'
import { UncontrolledTextInput } from '@src/components/Inputs/TextInput/TextInput'
import { Totals } from '@src/components/Totals/Totals'
import { MerchantType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSafeArea } from '@src/hooks/useSafeArea'

import {
  Container,
  Description,
  OuterContainer,
  SubHeader,
} from './TableFulfilmentForm.style'
import { TableLoader } from './TableLoader'
import { TableFulfilmentFormSchema } from './useTableFulfilmentFormikProps/useTableFulfilmentFormikProps'

import { OrderSummary } from '../OrderSummary/OrderSummary'

export const TableFulfilmentForm: React.FC<
  FormikProps<TableFulfilmentFormSchema>
> = ({ values, handleChange, isSubmitting }) => {
  const { isMobile } = useBreakpoint()
  const { safeAreaInsetBottom } = useSafeArea()
  const {
    outlet: { isPendingTableNumbersEnabled, pendingTableNumbersCustomText },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const marketplace = useMarketplace()
  const { t } = useTranslation('checkout')

  return (
    <FlexGrowForm
      $windowHeight={window.innerHeight}
      $hasSafeArea={safeAreaInsetBottom > 0}
    >
      <FlexGrowScrollContainer>
        <OuterContainer>
          <CheckoutLoyaltyCardSlider />
          <Description>{t('how_will_we_find_you')}</Description>
          {!isPendingTableNumbersEnabled && (
            <Container>
              <SubHeader>{t('your_table')}</SubHeader>
              <TableLoader />
            </Container>
          )}

          {isPendingTableNumbersEnabled && (
            <Container>
              <p>{pendingTableNumbersCustomText}</p>
            </Container>
          )}

          <br />
          <FulfilmentNoteContainer>
            <UncontrolledTextInput
              name="tableNotes"
              touched
              label={t('table_service_note')}
              value={values.tableNotes}
              placeholder={
                marketplace.tableNoteText ||
                (marketplace.merchantType === MerchantType.RETAIL
                  ? t('service_note_example_retail')
                  : t('service_note_example'))
              }
              required={false}
              onChange={handleChange}
            />
          </FulfilmentNoteContainer>
          <OrderSummary tableId={values.tableId} />
          {(isMobile || safeAreaInsetBottom > 0) && <Totals />}
        </OuterContainer>
      </FlexGrowScrollContainer>
      <CheckoutButtonWrapperMobile>
        {!isMobile && safeAreaInsetBottom === 0 && <Totals />}
        <CheckoutButton
          loading={isSubmitting}
          type="submit"
          content={toUpper(t('continue_to_payment'))}
          dataTestId="table-confirm-continue-to-payment-button"
        />
      </CheckoutButtonWrapperMobile>
    </FlexGrowForm>
  )
}
