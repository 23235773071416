import styled from 'styled-components'

import { Button } from '@src/components/Button/Button'
import { LocationSVG } from '@src/components/SVGS/LocationSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    flex-direction: column;
  }
`

export const Container = styled.form`
  position: relative;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 12px;

  width: 100%;
  border: 2px solid transparent;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.brand};
  }
  margin: 0 0 32px 0;

  @media (min-width: ${breakpoints.phablet}px) {
    flex-direction: row;
    max-width: 600px;
  }
`

export const ShowEverywhereText = styled.button`
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: white;
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);

  &:hover {
    text-decoration: none;
  }
`

export const TextHelp = styled.div`
  color: white;
  font-size: 14px;
  white-space: pre-wrap;
  font-weight: 700;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
`

export const HelpTextContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin: 0 0 16px 0;
`

export const Input = styled.input`
  box-sizing: border-box;
  height: 100%;
  border: none;
  padding: 8px 42px 8px 0px;
  outline: none;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  @media (min-width: ${breakpoints.phablet}px) {
    text-align: left;
    width: 50%;
    padding: 8px 0px 8px 16px;
  }
  min-width: 180px;
`

export const SearchButton = styled(Button)`
  padding: 0px 16px;
  width: 100%;
  @media (min-width: ${breakpoints.phablet}px) {
    width: auto;
  }
`

export const LocationButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  margin-bottom: 8px;
  @media (min-width: ${breakpoints.phablet}px) {
    margin: 0;
  }
`

export const LocationButton = styled.button`
  border: none;
  cursor: pointer;
  height: max-content;
  padding: 0px;
  background-color: transparent;
  padding: 2px 0 0 2px;
`

export const StyledLocationSVG = styled(LocationSVG)`
  height: 42px;
  width: 42px;
  padding: 0px;
  cursor: pointer;

  .circle {
    fill: #fff;
    stroke: #fff;
  }

  .arrow {
    fill: ${({ theme }) => theme.colors.brand};
  }

  .circle,
  .arrow {
    transition: 0.2s ease;
    transition-property: fill, stroke;
  }

  &:hover {
    .circle {
      fill: ${({ theme }) => theme.colors.brand};
      stroke: ${({ theme }) => theme.colors.brand};
    }

    .arrow {
      fill: #fff;
    }
  }
`
