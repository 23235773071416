import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import { BottomSheetWrapper } from '@src/components/BottomSheetWrapper/BottomSheetWrapper'
import { LoyaltyCard } from '@src/components/CustomerLoyaltyCard/CustomerLoyaltyCard'
import { Helmet } from '@src/components/Helmet'
import { breakpoints } from '@src/constants/breakpoints'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { allOutletDiscountsQuery } from '@src/hooks/sharedQueries/useAllDiscounts/queries/__generated__/getAllDiscounts.graphql-interface'
import { useCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { useBasketOutletId } from '@src/hooks/useBasketOutletId'
import { useFindMenuItemModalData } from '@src/hooks/useFindMenuItemModalData'
import { useMenuItemQueryParam } from '@src/hooks/useMenuItemQueryParam'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'
import { screenResolutionVar } from '@src/models/screenResolution'

import { MenuGroup } from './MenuGroup/MenuGroup'
import { MenuItemModal } from './MenuItemModal/MenuItem.modal'
import { MenuItemSearchResults } from './MenuItemSearchResults/MenuItemSearchResults'
import {
  DealsAndOffersContainer,
  SingleDiscountContainer,
} from './OutletMenuStyles'

import { OutletMenuItemGroupTree } from '../menuItemGroupTreeType'
import { SingleDiscount } from '../OutletDiscounts/SingleDiscount'
import {
  Header,
  ScrollToContainer,
} from '../OutletMenu/MenuGroup/MenuGroup.styles'
import { DealsAndOffersSkeleton } from '../OutletPageSkeleton/DealsAndOffersSkeleton'

export const OutletMenu: React.FC<{
  activeMenuId: string | null
  allDiscounts: allOutletDiscountsQuery | undefined
  setActiveMenuId: (val: string | null) => void
  fullMenuItemGroupTree: OutletMenuItemGroupTree
  availableMenuItemGroupTree: OutletMenuItemGroupTree
  manualNavigation: boolean
  discountsLoading: boolean
  showMenuSearchResults: boolean
  menuSearchIds: string[]
  searchTerm: string
}> = ({
  activeMenuId,
  setActiveMenuId,
  allDiscounts,
  fullMenuItemGroupTree,
  availableMenuItemGroupTree,
  manualNavigation,
  discountsLoading,
  menuSearchIds,
  searchTerm,
  showMenuSearchResults,
}) => {
  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const [modalMenuItemId, setMenuItemQueryParam] = useMenuItemQueryParam()
  const { width } = useReactiveVar(screenResolutionVar)
  const { t } = useTranslation('outletOverview')

  const { modalData } = useFindMenuItemModalData({
    fullMenuItemGroupTree,
    modalMenuItemId,
  })

  const { data, loading: loyaltyCardsLoading } =
    useCustomerDetailsAndAddressesQuery({
      addressAcceptingOrdersToOutletId: outlet.id,
    })

  const { data: basketTotals } = useBasketTotals()
  const { basketOutletId } = useBasketOutletId()

  const thisOutletIsInBasket = basketOutletId === outlet.id

  const getLoyaltyCards = () => {
    if (
      thisOutletIsInBasket &&
      basketTotals?.checkoutBasketTotals?.loyaltyCards
    ) {
      return basketTotals.checkoutBasketTotals.loyaltyCards.length
        ? basketTotals.checkoutBasketTotals.loyaltyCards
        : outlet.outletLoyaltyCards
    }

    const cards =
      data?.customerDetails?.loyaltyCards ?? outlet.outletLoyaltyCards

    return cards
  }

  const loyaltyCards = getLoyaltyCards()

  // seperate intersection observer for discounts
  const rootMargin = (): string => {
    if (width > breakpoints.tablet) {
      return `${-window.innerHeight * 0.4}px`
    } else return `${-window.innerHeight * 0.3}px`
  }

  const { ref, inView, entry } = useInView({
    root: null,
    rootMargin: `0px 0px  ${rootMargin()}
     0px`,
    threshold: 1,
    trackVisibility: true,
    delay: 300,
  })

  useEffect(() => {
    if (
      inView &&
      entry?.isIntersecting &&
      activeMenuId !== 'discountMenuItem' &&
      !manualNavigation
    ) {
      setActiveMenuId('discountMenuItem')
    }
  }, [ref, inView])

  return (
    <>
      <Helmet title={`${outlet.displayName} Menu`} />
      {discountsLoading || loyaltyCardsLoading ? (
        <DealsAndOffersSkeleton />
      ) : (
        <div>
          {(allDiscounts?.allDiscountsByOutletId?.length ||
            loyaltyCards?.length) &&
          !showMenuSearchResults ? (
            <>
              <ScrollToContainer id="discountMenuItem" aria-hidden />
              <DealsAndOffersContainer ref={ref}>
                <Header hasDescription={false}>{t('deals_and_offers')}</Header>
                <SingleDiscountContainer>
                  {loyaltyCards.map(loyaltyCard => {
                    return (
                      <LoyaltyCard
                        loyaltyCard={loyaltyCard}
                        key={loyaltyCard.id}
                        basketTotals={
                          thisOutletIsInBasket && basketTotals
                            ? basketTotals
                            : undefined
                        }
                      />
                    )
                  })}
                  {allDiscounts?.allDiscountsByOutletId.map(discount => (
                    <div key={discount.id}>
                      <SingleDiscount allDiscounts={discount} />
                    </div>
                  ))}
                </SingleDiscountContainer>
              </DealsAndOffersContainer>
            </>
          ) : null}
        </div>
      )}
      <>
        {showMenuSearchResults ? (
          <MenuItemSearchResults
            outletId={outlet.id}
            menuSearchIds={menuSearchIds}
            searchTerm={searchTerm}
          />
        ) : (
          availableMenuItemGroupTree.map((outletMenuGroup, index) => {
            return outletMenuGroup.parentMenu ? (
              <MenuGroup
                hideName={index === 0}
                key={outletMenuGroup.parentMenu.id}
                outletId={outlet.id}
                outletParentMenu={outletMenuGroup.parentMenu}
                outletSubMenus={outletMenuGroup.subMenus}
                activeMenuId={activeMenuId}
                setActiveMenuId={setActiveMenuId}
                manualNavigation={manualNavigation}
              />
            ) : null
          })
        )}
      </>

      <BottomSheetWrapper
        open={!!modalMenuItemId}
        onClose={() => {
          setMenuItemQueryParam(undefined, 'replaceIn')
        }}
        headerColor="#fff"
        backgroundColor="#fff"
      >
        {modalData && (
          <MenuItemModal
            modalData={modalData}
            closeModal={() => {
              setMenuItemQueryParam(undefined, 'replaceIn')
            }}
          />
        )}
      </BottomSheetWrapper>
    </>
  )
}
