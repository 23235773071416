import { useLazyQuery } from '@apollo/client'
import { toUpper } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { apolloClient } from '@src/apolloClient'
import { ButtonType } from '@src/components/Button'
import {
  CountryCodeType,
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { outletFulfilmentDocument } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilmentData/queries/__generated__/outletFulfilment.graphql-interface'
import { useMarketplace } from '@src/hooks/useMarketplace'

import {
  Title,
  Underlay,
  Container,
  LocationAlignmentContainer,
  PostcodeInputContainer,
  PostcodeInput,
  SearchButton,
  SearchIcon,
  StyledChoiceButton,
  CenteredText,
} from '../MenuItemModalAlertStyles'
import { postcodeDeliveryEstimateDocument } from '../queries/__generated__/postcodeDeliveryEstimate.graphql-interface'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'

export const PostcodeInputModal: React.FC<{
  onSelected: () => void
  narrowFulfilmentMethods?: NarrowFulfilmentMethodInputType[]
  locationError?: boolean
}> = ({ onSelected, narrowFulfilmentMethods, locationError = false }) => {
  const { t } = useTranslation('navigation')
  const fulfilmentFilter = useFulfilmentFilter()
  const basketItems = useBasketItems()
  const currentOutletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const [postcode, setPostcode] = useState(
    currentOutletFulfilment.data.historicalData.postAndCountryCode?.postcode ??
      ''
  )
  const {
    urlPath: merchantURLPath,
    merchantType,
    ...marketplace
  } = useMarketplace()
  const navigate = useNavigate()
  const [checkPostcodeIsAvailableForDelivery, { loading }] = useLazyQuery(
    postcodeDeliveryEstimateDocument
  )

  // this controls a modal which is show if the user has entered a postcode
  // but the postcode is not available for delivery from the current outlet
  const [showDeliveryUnavailableModal, setShowDeliveryUnavailableModal] =
    useState(locationError)

  useEffect(() => {
    setShowDeliveryUnavailableModal(locationError)
  }, [locationError])

  const handleFormSubmit = async () => {
    // fetch an estimate to check if the outlet offers delivery to the entered postcode
    const postcodeData = await checkPostcodeIsAvailableForDelivery({
      variables: {
        outletId: currentOutletFulfilment.outlet.id,
        postcode,
      },
    })

    currentOutletFulfilment.setCurrentFulfilment({
      type: CurrentFulfilmentType.DELIVERY_POSTCODE,
      postAndCountryCode: {
        postcode: postcode,
        countryCode: marketplace.country.ISO3166Alpha2 as CountryCodeType,
      },
      deliveryPreorderWindow:
        currentOutletFulfilment.data.historicalData.deliveryPreorderWindow,
    })
    // if the outlet offers deliver to the entered postcode, save the fulfilment
    // otherwise show the delivery-unavailable modal to the user
    if (postcodeData.data?.deliveryEstimateForPostcode.id) {
      onSelected()
    } else if (!postcodeData.data?.deliveryEstimateForPostcode) {
      await apolloClient.refetchQueries({
        include: [outletFulfilmentDocument],
      })
      setShowDeliveryUnavailableModal(true)
    }
  }

  return (
    <>
      <Underlay />
      <Container>
        {showDeliveryUnavailableModal ? (
          <>
            <Title>
              {t('delivery_not_available', {
                locationType: t('postcode').toLowerCase(),
              })}
            </Title>
            <br />
            <StyledChoiceButton
              buttonType={ButtonType.PRIMARY}
              content={t('new_postcode')}
              onClick={() => setShowDeliveryUnavailableModal(false)}
            />
            {currentOutletFulfilment.outlet.prioritisedFulfilmentMethods.includes(
              NarrowFulfilmentMethodInputType.COLLECTION
            ) &&
              (!narrowFulfilmentMethods ||
                narrowFulfilmentMethods.includes(
                  NarrowFulfilmentMethodInputType.COLLECTION
                )) && (
                <StyledChoiceButton
                  buttonType={ButtonType.SECONDARY}
                  content={t('order_for_collection')}
                  onClick={() => {
                    onSelected()
                    // current fulfilment changes before the item is added, rerendering the page
                    setTimeout(() => {
                      currentOutletFulfilment.setCurrentFulfilment({
                        type: CurrentFulfilmentType.COLLECTION,
                        collectionPreorderDatetime:
                          currentOutletFulfilment.data.historicalData
                            .collectionPreorderDatetime,
                      })
                    }, 1000)
                  }}
                />
              )}
            <CenteredText>{t('or')}</CenteredText>
            <StyledChoiceButton
              buttonType={ButtonType.SECONDARY}
              content={t('go_back_and_find_outlets', {
                merchantType:
                  merchantType === 'RESTAURANT'
                    ? t('restaurants')
                    : t('businesses'),
                postcode: postcode,
              })}
              onClick={() => {
                fulfilmentFilter.setLocationType({
                  type: LocationType.POSTCODE,
                  postAndCountryCode: {
                    postcode,
                    countryCode: CountryCodeType.GB,
                  },
                })
                basketItems.clear({ shouldResetCheckoutRouter: true })
                currentOutletFulfilment.updateHistoricalData({
                  orderNotes: '',
                  deliveryNotes: '',
                })
                navigate(`/${merchantURLPath}`)
              }}
            />
          </>
        ) : (
          <>
            <Title>{t('unknown_location')}</Title>
            <br />
            <LocationAlignmentContainer>
              <PostcodeInputContainer>
                <PostcodeInput
                  id="POSTCODE"
                  type="text"
                  placeholder={t('enter_your_postcode')}
                  onChange={e => setPostcode(toUpper(e.target.value))}
                  value={postcode}
                  title="Full valid postcode, e.g. 'LS29 8AF'"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      void handleFormSubmit()
                    }
                  }}
                />
                <SearchButton
                  type="button"
                  disabled={postcode.length <= 2 || loading}
                  onClick={handleFormSubmit}
                >
                  <SearchIcon id="postcode-search-icon" title={t('search')} />
                </SearchButton>
              </PostcodeInputContainer>
            </LocationAlignmentContainer>
          </>
        )}
      </Container>
    </>
  )
}
