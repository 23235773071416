import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import {
  useOutletFulfilment,
  OutletFulfilmentStateType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useLazyTablesQuery } from '@src/hooks/sharedQueries/useTables/useTables'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'

// schema fields for new and existing addresses
const tableFulfilmentFormSchema = z.object({
  tableId: z.string(),
  ageVerificationConfirmed: z.boolean(),
  tableNotes: z.string(),
})
export type TableFulfilmentFormSchema = z.infer<
  typeof tableFulfilmentFormSchema
>

export const useTableFulfilmentFormikProps = () => {
  const {
    data: { historicalData },
    setCurrentFulfilment,
    outlet,
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { t } = useTranslation('checkout')
  const checkoutRouter = useCheckoutRouter()

  // used to assert if the table is valid
  const [tablesQuery] = useLazyTablesQuery({
    outletId: outlet.id,
  })

  // complete schema with refinement to only require age verification if necessary
  // used for validation onBlur
  const zodValidationSchema = useMemo(
    () =>
      outlet.isPendingTableNumbersEnabled
        ? tableFulfilmentFormSchema
        : tableFulfilmentFormSchema.refine(
            async values => {
              const { data } = await tablesQuery()
              const tableIds = data?.tables.map(table => table.id)
              return tableIds?.includes(values.tableId)
            },
            {
              message: t('table_id_required'),
              path: ['tableId'],
            }
          ),
    [t, tablesQuery]
  )

  // define initial values based on current fulfilment
  const initialValues: TableFulfilmentFormSchema = useMemo(() => {
    return {
      ageVerificationConfirmed:
        historicalData.ageVerificationConfirmed || false,
      tableNotes: historicalData.tableNotes || '',
      tableId: historicalData.tableId || '',
    }
  }, [historicalData])

  const handleSubmit = (values: TableFulfilmentFormSchema) => {
    void setCurrentFulfilment({
      type: CurrentFulfilmentType.TABLE,
      tableNotes: values.tableNotes,
      ageVerificationConfirmed: values.ageVerificationConfirmed,
      tableId: values.tableId,
    })

    void checkoutRouter.override(
      outlet.restaurant.allowAddOnItems
        ? CheckoutRoute.OFFERS
        : CheckoutRoute.PAYMENT
    )
  }

  return {
    zodValidationSchema,
    initialValues,
    handleSubmit,
  } as const
}
