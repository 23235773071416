import styled from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'

export const UncontrolledCheckboxInputContainer = styled.div`
  margin-top: 16px;
  padding: 0;
`

export const AddAddressFriendlyNameText = styled.p`
  font-size: 16px;
  padding: 8px 0 8px; 0;
  margin: 0;
`

export const AddressBookButton = styled(AccountSectionNavButton)`
  float: right;
  font-size: 14px;
  display: flex;
  flex-flow: row-reverse;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

export const StyledAddressBookButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  height: 32px;
  &:hover {
    text-decoration: none;
  }
`
