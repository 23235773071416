import React from 'react'
import Color from 'color'
import styled, { css } from 'styled-components'

import { ItemAddSVG } from '@src/components/SVGS/ItemAddSVG'
import { MinusSVG } from '@src/components/SVGS/MinusSVG'
import { PlusSVG, PlusSVGTypes } from '@src/components/SVGS/PlusSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{
  borderColor?: string
  disabled: boolean
}>`
  height: 32px;
  width: 88px;
  box-sizing: border-box;
  padding: 0px;
  border-radius: 24px;
  border: none;
  display: flex;
  justify-content: space-between;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

export const ActionButton = styled.button.attrs({
  type: 'button',
})`
  width: 28px;
  height: 28px;
  outline: 0;
  border-radius: 100%;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;

  &:active {
    background-color: ${({ theme }) => theme.colors.darkGrey};
    color: ${({ theme }) => theme.colors.brand};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.brand};
    color: white;
  }
  &:disabled {
    opacity: 0.2;
    background-color: white;
    &:hover {
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.brand};
      background-color: inherit;
    }
  }
`

export const StyledPricePlusSVG = styled(ItemAddSVG)`
  width: 18px;
  height: 18px;
  svg {
    fill: ${({ theme }) => theme.colors.brandHighlight};
  }
`

export const StyledPlusSVG = styled(PlusSVG)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`

export const StyledMinusSVG = (props: PlusSVGTypes) => (
  <StyledPlusSVG as={MinusSVG} {...props} />
)

export const ItemCount = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  min-width: 24px;
  text-align: center;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.brand};
`

export const Price = styled.div<{
  isDisabled: boolean
  isMenuItem?: boolean
}>`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brandHighlight};
  display: flex;
  align-items: center;

  span {
    margin-right: 4px;
  }

  transition: 0.3s ease;
  transition-property: color, background-color, border-color;

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      color: ${Color(theme.colors.mainText).alpha(0.6).hexa()};
    `}

  // TODO just extend it
    ${({ isMenuItem }) =>
    isMenuItem &&
    css`
      @media (min-width: ${breakpoints.tablet}px) {
        font-size: 14px;
        margin-bottom: 0px;
      }
      @media (min-width: ${breakpoints.desktop}px) {
        font-size: 16px;
        margin-bottom: 0px;
      }
    `}
`

export const StruckOutText = styled.span`
  font-weight: normal;
  text-decoration: line-through;
`
