export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: unknown
}

export type AddCustomerCard = {
  __typename: 'AddCustomerCard'
  card: Maybe<CardPartial>
  message: Maybe<Scalars['String']>
}

export type AddOnItem = MenuItemInterface & {
  __typename: 'AddOnItem'
  SKU: Maybe<Scalars['String']>
  VATinclusive: Maybe<Scalars['Boolean']>
  addOnMaxPrice: Maybe<Scalars['Int']>
  ageRestricted: Maybe<Scalars['Boolean']>
  allergens: Array<Maybe<Allergen>>
  archived: Scalars['Boolean']
  bulkyItem: Scalars['Boolean']
  categoryTag: Maybe<Scalars['String']>
  costPrice: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  featuredImages: Array<FeaturedImage>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  isDairyFree: Maybe<Scalars['Boolean']>
  isGlutenFree: Maybe<Scalars['Boolean']>
  isKeto: Scalars['Boolean']
  isPromoted: Maybe<Scalars['Boolean']>
  isVegan: Maybe<Scalars['Boolean']>
  isVegetarian: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  maxPurchaseQuantity: Maybe<Scalars['Int']>
  menuItemId: Scalars['String']
  minimumPurchasePrice: Maybe<Scalars['Int']>
  name: Scalars['String']
  optionPositions: Array<Scalars['String']>
  options: Array<Option>
  outlet: Maybe<Outlet>
  outletHidden: Maybe<Scalars['Boolean']>
  outletSoldOut: Maybe<Scalars['Boolean']>
  parentMenu: MenuItemGroup
  position: Maybe<Scalars['Int']>
  price: Scalars['Int']
  soldOut: Maybe<Scalars['Boolean']>
  spiceLevel: Scalars['Int']
  vatRate: Maybe<Scalars['Float']>
}

export type Address = {
  __typename: 'Address'
  acceptsDelivery: Scalars['Boolean']
  city: Scalars['String']
  /** @deprecated Please use countryCode enum on the base address. */
  country: Maybe<Country>
  countryCode: CountryCodeType
  default: Scalars['Boolean']
  firstLine: Scalars['String']
  id: Scalars['ID']
  key: Maybe<Scalars['String']>
  name: Scalars['String']
  postcode: Scalars['String']
  secondLine: Maybe<Scalars['String']>
  thirdLine: Maybe<Scalars['String']>
}

export type AddressacceptsDeliveryArgs = {
  outletId?: InputMaybe<Scalars['ID']>
}

export type AddressCreateInput = {
  city: Scalars['String']
  countryCode: CountryCodeType
  firstLine: Scalars['String']
  postcode: Scalars['String']
  secondLine?: InputMaybe<Scalars['String']>
  thirdLine?: InputMaybe<Scalars['String']>
}

export enum Allergen {
  DAIRY_FREE = 'DAIRY_FREE',
  GLUTEN_FREE = 'GLUTEN_FREE',
  KETO = 'KETO',
  VEGAN = 'VEGAN',
  VEGETARIAN = 'VEGETARIAN',
}

export type AllowedOutlets = {
  __typename: 'AllowedOutlets'
  id: Scalars['ID']
  name: Scalars['String']
}

export type AppliedDiscount = {
  __typename: 'AppliedDiscount'
  discount: Discount
  reductionAmount: Scalars['Int']
}

export type ArchiveAddressResponse = {
  __typename: 'ArchiveAddressResponse'
  deletedId: Scalars['String']
  message: Scalars['String']
  newDefaultAddressId: Maybe<Scalars['String']>
}

export type ArchiveCustomerResponse = {
  __typename: 'ArchiveCustomerResponse'
  archivedId: Scalars['String']
  message: Scalars['String']
}

export type AvailabilityTime = {
  __typename: 'AvailabilityTime'
  end: DayAndTime
  id: Scalars['ID']
  start: DayAndTime
}

export type AvailableFulfilment = {
  __typename: 'AvailableFulfilment'
  fulfilmentMethod: NarrowFulfilmentMethodInputType
  fulfilmentTimeTypes: Array<FulfilmentTimeType>
}

export type BasketItem = {
  __typename: 'BasketItem'
  menuItemId: Scalars['String']
  notes: Maybe<Scalars['String']>
  optionIds: Array<Scalars['String']>
}

/**
 * This is similar to the fulfilmentInput type, but with the addition of fulfilment methods that are to broad to place an order with, eg POSTCODE.
 *  Validation strictly asserts input must match one of these schemas:
 *
 *     ```
 *     | {
 *       broadFulfilmentMethod: COLLECTION
 *       preorderFor?: DateTime
 *     }
 *     | {
 *       broadFulfilmentMethod: TABLE
 *       tableId: String
 *     }
 *     | {
 *       broadFulfilmentMethod: ADDRESS
 *       addressId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     | {
 *       broadFulfilmentMethod: POSTCODE
 *       postcode: String
 *       countryCode: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     | {
 *       broadFulfilmentMethod: COORDINATES
 *       latitude: Float
 *       longitude: Float
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     | {
 *       broadFulfilmentMethod: FIXED_ZONE_DELIVERY
 *       deliveryZoneId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     ```
 *
 */
export type BroadFulfilmentInput = {
  addressId?: InputMaybe<Scalars['String']>
  broadFulfilmentMethod: BroadFulfilmentMethodInputType
  countryCode?: InputMaybe<Scalars['String']>
  deliveryZoneId?: InputMaybe<Scalars['String']>
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  postcode?: InputMaybe<Scalars['String']>
  preorderDeliveryWindow?: InputMaybe<PreorderDeliveryWindowInput>
  preorderFor?: InputMaybe<Scalars['DateTime']>
  tableId?: InputMaybe<Scalars['String']>
}

export enum BroadFulfilmentMethodInputType {
  ADDRESS = 'ADDRESS',
  COLLECTION = 'COLLECTION',
  COORDINATES = 'COORDINATES',
  FIXED_ZONE_DELIVERY = 'FIXED_ZONE_DELIVERY',
  POSTCODE = 'POSTCODE',
  TABLE = 'TABLE',
}

export type BusinessSegment = {
  __typename: 'BusinessSegment'
  availabilityEndDate: Maybe<Scalars['DateTime']>
  availabilityStartDate: Maybe<Scalars['DateTime']>
  availabilityTimes: Maybe<Scalars['JSON']>
  cards: Array<BusinessSegmentsMultiCardsResponse>
  categories: Array<Cuisine>
  description: Maybe<Scalars['String']>
  filterSegment: Scalars['Boolean']
  id: Scalars['ID']
  linkCardOrderBy: Maybe<SegmentOrderBy>
  linkToSegmentIndex: Scalars['Boolean']
  name: Scalars['String']
  orderBy: Maybe<SegmentOrderBy>
  outletLimit: Scalars['Int']
  outlets: Array<Outlet>
  position: Scalars['Int']
  showDiscountLabels: Scalars['Boolean']
  showFeaturedFlags: Scalars['Boolean']
  showName: Scalars['Boolean']
  type: BusinessSegmentType
}

export type BusinessSegmentcardsArgs = {
  outletsInput: OutletsInput
}

export type BusinessSegmentoutletsArgs = {
  outletsInput?: InputMaybe<OutletsInput>
}

/** Cards assigned to the business segment */
export type BusinessSegmentCardsResponse = LinkCard | Outlet

export type BusinessSegmentCategory = {
  __typename: 'BusinessSegmentCategory'
  category: Cuisine
  id: Scalars['ID']
  position: Scalars['Int']
}

export type BusinessSegmentOutletsInput = {
  addressId?: InputMaybe<Scalars['String']>
  businessSegmentId: Scalars['String']
  coordinates?: InputMaybe<CoordinatesInput>
  deliveryZoneId?: InputMaybe<Scalars['String']>
  fulfilmentMethods?: InputMaybe<Array<OrderFulfillmentMethods>>
  limitOutlets?: InputMaybe<Scalars['Boolean']>
  locationType?: InputMaybe<LocationType>
  marketplaceId?: InputMaybe<Scalars['String']>
  narrowFulfilmentMethods?: InputMaybe<Array<NarrowFulfilmentMethodInputType>>
  postAndCountryCode?: InputMaybe<PostAndCountryCodeInput>
  selectedDate?: InputMaybe<Scalars['DateTime']>
  whenType?: InputMaybe<FulfilmentFilterWhenType>
}

export enum BusinessSegmentType {
  CATEGORIES = 'CATEGORIES',
  OUTLETS = 'OUTLETS',
}

export type BusinessSegmentsFilter = {
  __typename: 'BusinessSegmentsFilter'
  attribute: Scalars['String']
  comparator: Scalars['String']
  filterGroupType: FilterGroupType
  id: Scalars['ID']
  values: Array<Scalars['String']>
}

/** Data for segment cards */
export type BusinessSegmentsMultiCardsResponse = Cuisine | LinkCard | Outlet

export type CardInPersonOrderResponse = {
  __typename: 'CardInPersonOrderResponse'
  orders: Array<Order>
  paymentIntentClientSecret: Scalars['String']
}

/** This will be the response when the payment.paymentMethod is CARD */
export type CardOrderResponse = {
  __typename: 'CardOrderResponse'
  orders: Array<Order>
  /** This is used to complete additional 3DSecure checks on the client. If null, no checks are required. */
  paymentIntentClientSecret: Maybe<Scalars['String']>
}

export type CardPartial = {
  __typename: 'CardPartial'
  brand: Maybe<Scalars['String']>
  exp_month: Maybe<Scalars['String']>
  exp_year: Maybe<Scalars['String']>
  id: Scalars['String']
  last4: Scalars['String']
}

export type CardPayment = {
  __typename: 'CardPayment'
  brand: Scalars['String']
  errorMessage: Maybe<Scalars['String']>
  exp_month: Scalars['Int']
  exp_year: Scalars['Int']
  last4: Scalars['String']
}

export type CashOrderResponse = {
  __typename: 'CashOrderResponse'
  orders: Array<Order>
}

export type CashPayment = {
  __typename: 'CashPayment'
  errorMessage: Maybe<Scalars['String']>
}

export enum CategoryEmojiOrImage {
  EMOJI = 'EMOJI',
  IMAGE = 'IMAGE',
}

export type ChangePasswordResponse = {
  __typename: 'ChangePasswordResponse'
  customer: Customer
  message: Scalars['String']
}

export type CheckoutBasketTotalsInput = {
  addOnBasketItemsTotal?: InputMaybe<Scalars['Int']>
  fulfilment: BroadFulfilmentInput
  nonAddOnBasketItemsTotal: Scalars['Int']
  outletId: Scalars['String']
  paymentMethod?: InputMaybe<Scalars['String']>
  /** This is the id of the voucher that is being applied to the basket. It overrides the memberDiscountId if both are present. */
  voucherDiscountId?: InputMaybe<Scalars['String']>
}

export type CheckoutTotals = {
  __typename: 'CheckoutTotals'
  basketSubtotal: Scalars['Int']
  expiryAt: Scalars['String']
  fulfilmentCost: Scalars['Int']
  fulfilmentType: Scalars['String']
  /**
   * This id references the stored data about the fulfilment.
   * ie Who is fulfilling the order, from where, to where, for what cost etc.
   * It is a passed to the createOrder mutation as the fulfilmentId arg for delivery orders,
   * to ensure that the price seen by the customer is the price charged.
   */
  id: Scalars['String']
  serviceCharge: Scalars['Int']
  total: Scalars['Int']
}

export type CheckoutTotalsV2 = {
  __typename: 'CheckoutTotalsV2'
  addOnBasketItemsTotal: Scalars['Int']
  appliedDiscount: Maybe<AppliedDiscount>
  expiryAt: Scalars['String']
  fulfilmentCharge: Scalars['Int']
  /**
   * This id references the stored data about the fulfilment.
   * ie Who is fulfilling the order, from where, to where, for what cost etc.
   * It is a passed to the createOrder mutation as the fulfilmentId arg for delivery orders,
   * to ensure that the price seen by the customer is the price charged.
   */
  fulfilmentId: Scalars['String']
  /**
   * This is true if the fulfilmentCharge is a guaranteed.
   * It will be false if the location given is just a postcode.
   */
  isFixedFulfilment: Scalars['Boolean']
  loyaltyCards: Array<LoyaltyCard>
  nonAddOnBasketItemsTotal: Scalars['Int']
  serviceCharge: Scalars['Int']
  sumTotal: Scalars['Int']
}

export enum CleanOrderStatus {
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
  PREPARING = 'PREPARING',
  READY = 'READY',
  REJECTED = 'REJECTED',
}

export type CnameUnion = Marketplace | Restaurant

export enum Comparator {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
}

export type ConnectionInputObject = {
  pageSize?: InputMaybe<Scalars['Int']>
  startKey?: InputMaybe<Scalars['String']>
}

export type CoordinatesInput = {
  lat: Scalars['Float']
  lng: Scalars['Float']
}

export type Country = {
  __typename: 'Country'
  ISO3166Alpha2: Scalars['String']
  ISO3166Alpha3: Scalars['String']
  currency: Currency
  distanceUnit: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  postCodeRegex: Maybe<Scalars['String']>
}

export enum CountryCodeType {
  GB = 'GB',
}

export type CreateAddressInput = {
  city: Scalars['String']
  countryCode: CountryCodeType
  default?: InputMaybe<Scalars['Boolean']>
  firstLine: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  postcode: Scalars['String']
  secondLine?: InputMaybe<Scalars['String']>
  thirdLine?: InputMaybe<Scalars['String']>
}

export type CreateAddressResponse = {
  __typename: 'CreateAddressResponse'
  address: Address
  message: Scalars['String']
}

export type CreateOrderInput = {
  customerDeliveryNotes?: InputMaybe<Scalars['String']>
  customerOrderNotes?: InputMaybe<Scalars['String']>
  discountId?: InputMaybe<Scalars['String']>
  fulfilment: FulfilmentInput
  orderItems: Array<OrderItemInput>
  payment: PaymentInput
  voucherKey?: InputMaybe<Scalars['String']>
}

/** The response from creating an order, it is a union of types with different fields for different order types. */
export type CreateOrderResponse =
  | CardInPersonOrderResponse
  | CardOrderResponse
  | CashOrderResponse
  | ManualConfirmationCardOrderResponse

export type Cuisine = {
  __typename: 'Cuisine'
  emoji: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageSrc: Maybe<Scalars['String']>
  key: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type Currency = {
  __typename: 'Currency'
  decimalMark: Maybe<Scalars['String']>
  decimalPlaces: Maybe<Scalars['Int']>
  iso4217: Scalars['String']
  name: Scalars['String']
  prefix: Maybe<Scalars['String']>
  shortName: Scalars['String']
  subunit: Maybe<Scalars['String']>
  subunitToUnit: Maybe<Scalars['Int']>
  suffix: Maybe<Scalars['String']>
  symbol: Scalars['String']
  thousandsSeparator: Maybe<Scalars['String']>
}

export type CustomLinkFooter = {
  __typename: 'CustomLinkFooter'
  id: Scalars['ID']
  link: Scalars['String']
  position: Scalars['Int']
  title: Scalars['String']
}

export type CustomLinkHeader = {
  __typename: 'CustomLinkHeader'
  id: Scalars['ID']
  link: Scalars['String']
  position: Scalars['Int']
  title: Scalars['String']
}

export type Customer = {
  __typename: 'Customer'
  archived: Scalars['Boolean']
  cards: CustomerCards
  deliveryAddresses: Array<Address>
  email: Scalars['String']
  failedAttempts: Maybe<Scalars['Int']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  isOnboarding: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  loyaltyCards: Array<LoyaltyCard>
  marketplace: Marketplace
  optOutMarketingEmail: Maybe<Scalars['Boolean']>
  optOutMarketingPush: Maybe<Scalars['Boolean']>
  optOutMarketingSMS: Maybe<Scalars['Boolean']>
  optOutMenuMarketing: Maybe<Scalars['Boolean']>
  optOutNewRestaurantMarketing: Maybe<Scalars['Boolean']>
  optOutPromotionMarketing: Maybe<Scalars['Boolean']>
  password: Scalars['String']
  phoneNumber: Maybe<Scalars['String']>
  registeredAt: Maybe<Scalars['DateTime']>
  resetToken: Maybe<Scalars['String']>
  stripeCustomerId: Maybe<Scalars['String']>
  totalOrderCount: Maybe<Scalars['Int']>
  totalValue: Maybe<Scalars['Int']>
}

export type CustomerloyaltyCardsArgs = {
  outletId?: InputMaybe<Scalars['ID']>
}

export type CustomerCards = {
  __typename: 'CustomerCards'
  cards: Maybe<Array<CardPartial>>
  message: Maybe<Scalars['String']>
}

export type CustomerInput = {
  email?: InputMaybe<Scalars['String']>
  enrolmentKey?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
}

export type CustomerRegistrationResponse = {
  __typename: 'CustomerRegistrationResponse'
  customer: Maybe<Customer>
  message: Maybe<Scalars['String']>
  token: Maybe<Scalars['String']>
}

export type DayAndTime = {
  __typename: 'DayAndTime'
  day: Scalars['Int']
  id: Scalars['ID']
  time: Scalars['String']
}

export enum DefaultDatepickerOption {
  ANYTIME = 'ANYTIME',
  ASAP = 'ASAP',
  PREORDER = 'PREORDER',
}

export enum DefaultFulfilmentOption {
  ALL = 'ALL',
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  TABLE = 'TABLE',
}

export enum DefaultLocationOption {
  EVERYWHERE = 'EVERYWHERE',
  NEARME = 'NEARME',
  POSTCODE = 'POSTCODE',
}

export type DeleteCustomerCard = {
  __typename: 'DeleteCustomerCard'
  deletedCardId: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
}

export type DeliveryCost = {
  __typename: 'DeliveryCost'
  cost: Scalars['Int']
  expiryAt: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  minimumOrderValue: Maybe<Scalars['Int']>
  type: DeliveryCostType
}

export type DeliveryCostForCoordinates = {
  __typename: 'DeliveryCostForCoordinates'
  estimatedCost: Scalars['Int']
  outletId: Scalars['ID']
}

export enum DeliveryCostType {
  NETWORK_ESTIMATE = 'NETWORK_ESTIMATE',
  NETWORK_FALLBACK = 'NETWORK_FALLBACK',
  OUTLET_FIXED = 'OUTLET_FIXED',
}

export enum DeliveryNetworkBookingStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  ENROUTE_TO_CUSTOMER = 'ENROUTE_TO_CUSTOMER',
  ENROUTE_TO_OUTLET = 'ENROUTE_TO_OUTLET',
  NO_SHOW = 'NO_SHOW',
  PICKUP_BOOKED = 'PICKUP_BOOKED',
  UNKNOWN = 'UNKNOWN',
  WAITING_AT_CUSTOMER = 'WAITING_AT_CUSTOMER',
  WAITING_AT_OUTLET = 'WAITING_AT_OUTLET',
}

/** A window which for which delivery preorders orders can be placed */
export type DeliveryPreorderWindow = {
  __typename: 'DeliveryPreorderWindow'
  end: Scalars['DateTime']
  isFull: Scalars['Boolean']
  start: Scalars['DateTime']
}

/**
 * This is called DeliveryWindow but actually it could either be a delivery window or collection time.
 *     Either way, queries which return this type are deprecated in favour of Outlet.deliveryPreorderWindow and Outlet.collectionPreorderTimes
 */
export type DeliveryWindow = {
  __typename: 'DeliveryWindow'
  end: Maybe<Scalars['DateTime']>
  start: Maybe<Scalars['DateTime']>
}

export type DeliveryZone = {
  __typename: 'DeliveryZone'
  id: Scalars['ID']
  name: Scalars['String']
  outletCount: Scalars['Int']
}

export enum DestinationType {
  ADDRESS_ID = 'ADDRESS_ID',
  COORDINATES = 'COORDINATES',
  MULTI_LINE = 'MULTI_LINE',
}

export enum DetailedPaymentMethod {
  CARD = 'CARD',
  CARD_IN_PERSON = 'CARD_IN_PERSON',
  CASH = 'CASH',
  MANUAL_CONFIRMATION_CARD = 'MANUAL_CONFIRMATION_CARD',
  WALLET = 'WALLET',
}

export type Discount = {
  __typename: 'Discount'
  allowedOutlets: Array<Outlet>
  customerEmail: Maybe<Scalars['String']>
  customerEnrolled: Scalars['Boolean']
  daysOfWeek: Maybe<Array<Scalars['Int']>>
  discountAmount: Maybe<Scalars['Int']>
  discountPercentage: Maybe<Scalars['Float']>
  endDate: Maybe<Scalars['DateTime']>
  enrolmentKey: Maybe<Scalars['String']>
  id: Scalars['ID']
  key: Maybe<Scalars['String']>
  loyaltyCard: Maybe<LoyaltyCard>
  minimumSubtotalGross: Maybe<Scalars['Int']>
  name: Scalars['String']
  restaurant: Maybe<Restaurant>
  startDate: Maybe<Scalars['DateTime']>
  voucher: Maybe<Scalars['String']>
}

export type EditAddressInput = {
  city?: InputMaybe<Scalars['String']>
  countryCode?: InputMaybe<CountryCodeType>
  default?: InputMaybe<Scalars['Boolean']>
  firstLine?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  postcode?: InputMaybe<Scalars['String']>
  secondLine?: InputMaybe<Scalars['String']>
  thirdLine?: InputMaybe<Scalars['String']>
}

export type EditAddressResponse = {
  __typename: 'EditAddressResponse'
  address: Address
  message: Scalars['String']
}

export type EditContactDetails = {
  __typename: 'EditContactDetails'
  customer: Customer
}

export type EditDevicePushTokenInput = {
  deviceDescription: Scalars['String']
  pushToken: Scalars['String']
  pushTokenType: PushTokenType
}

export type FAQPartial = {
  __typename: 'FAQPartial'
  answer: Scalars['String']
  id: Scalars['ID']
  question: Scalars['String']
}

export type FeaturedImage = {
  __typename: 'FeaturedImage'
  caption: Maybe<Scalars['String']>
  id: Scalars['ID']
  src: Scalars['String']
}

export enum FilterGroupType {
  ALL = 'ALL',
  ANY = 'ANY',
}

export enum FulfillmentMethodType {
  COLLECTION = 'COLLECTION',
  COURIER = 'COURIER',
  DELIVERY = 'DELIVERY',
  TABLE = 'TABLE',
}

export enum FulfilmentFilterWhenType {
  ANYTIME = 'ANYTIME',
  ASAP = 'ASAP',
  PREORDER = 'PREORDER',
}

/**
 * Validation strictly asserts input must match one of these schemas:
 *
 *     ```
 *     | {
 *       fulfilmentMethod: COLLECTION
 *       preorderFor?: DateTime
 *       notes?: String
 *     }
 *     | {
 *       fulfilmentMethod: DELIVERY
 *       fulfilmentId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *       notes?: String
 *     }
 *     | {
 *       fulfilmentMethod: FIXED_ZONE_DELIVERY
 *       deliveryZoneId: String
 *       addressId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *       notes?: String
 *     }
 *     | {
 *       fulfilmentMethod: TABLE
 *       tableId: String
 *       notes?: String
 *     }
 *     ```
 *
 */
export type FulfilmentInput = {
  addressId?: InputMaybe<Scalars['String']>
  deliveryZoneId?: InputMaybe<Scalars['String']>
  fulfilmentId?: InputMaybe<Scalars['String']>
  fulfilmentMethod: FulfilmentMethodInputType
  notes?: InputMaybe<Scalars['String']>
  preorderDeliveryWindow?: InputMaybe<PreorderDeliveryWindowInput>
  preorderFor?: InputMaybe<Scalars['DateTime']>
  tableId?: InputMaybe<Scalars['String']>
}

/**
 * Validation strictly asserts input must match one of these schemas:
 *
 *     ```
 *     | {
 *       type: EVERYWHERE
 *
 *     }
 *     | {
 *       type: DELIVERY_ZONE
 *       deliveryZoneId: String!
 *     }
 *     | {
 *       type: POSTCODE
 *       postAndCountryCode: { postcode: 'LS12 2DS', countryCode: 'UK' }
 *     }
 *     | {
 *       type: ADDRESS
 *       addressId: String!
 *     }
 *     | {
 *       type: COORDINATES
 *       coordinates: {
 *         lat: Float!
 *         lng: Float!
 *       },
 *     }
 *     ```
 *
 */
export type FulfilmentLocationInput = {
  addressId?: InputMaybe<Scalars['String']>
  coordinates?: InputMaybe<CoordinatesInput>
  deliveryZoneId?: InputMaybe<Scalars['String']>
  postAndCountryCode?: InputMaybe<PostAndCountryCodeInput>
  type: LocationType
}

export enum FulfilmentMethodInputType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  FIXED_ZONE_DELIVERY = 'FIXED_ZONE_DELIVERY',
  TABLE = 'TABLE',
}

export type FulfilmentTime = {
  __typename: 'FulfilmentTime'
  end: Scalars['DateTime']
  limit: Maybe<Scalars['Int']>
  start: Maybe<Scalars['DateTime']>
}

export enum FulfilmentTimeType {
  ASAP = 'ASAP',
  PREORDER = 'PREORDER',
}

export type GroupedOrderItem = {
  __typename: 'GroupedOrderItem'
  id: Scalars['ID']
  isAddOnItem: Maybe<Scalars['Boolean']>
  menuItemId: Scalars['String']
  name: Scalars['String']
  optionItems: Array<OrderOptionItem>
  quantity: Scalars['Int']
  singleItemNotes: Maybe<Scalars['String']>
  totalPrice: Scalars['Int']
}

export type InPersonCardPayment = {
  __typename: 'InPersonCardPayment'
  errorMessage: Maybe<Scalars['String']>
}

export type KioskLoginInput = {
  pin?: InputMaybe<Scalars['String']>
  serialNumber?: InputMaybe<Scalars['String']>
}

export type KioskLoginSuccess = {
  __typename: 'KioskLoginSuccess'
  friendlyName: Scalars['String']
  id: Scalars['ID']
  locationId: Scalars['String']
  marketplace: Marketplace
  outlets: Array<Outlet>
  token: Scalars['String']
}

export type LinkCard = {
  __typename: 'LinkCard'
  buttonText: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  menuItem: Maybe<MenuItem>
  name: Scalars['String']
  outlet: Maybe<Outlet>
  url: Maybe<Scalars['String']>
}

export enum LocationSelectionOption {
  DELIVERY_ZONE = 'DELIVERY_ZONE',
  EVERYWHERE = 'EVERYWHERE',
  LOCATION = 'LOCATION',
  POSTCODE = 'POSTCODE',
  SAVED_ADDRESSES = 'SAVED_ADDRESSES',
}

export enum LocationType {
  ADDRESS = 'ADDRESS',
  COORDINATES = 'COORDINATES',
  DELIVERY_ZONE = 'DELIVERY_ZONE',
  EVERYWHERE = 'EVERYWHERE',
  POSTCODE = 'POSTCODE',
}

export type LoginInput = {
  email?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}

export type LoginSuccess = {
  __typename: 'LoginSuccess'
  customer: Customer
  token: Scalars['String']
}

export type LoyaltyCard = {
  __typename: 'LoyaltyCard'
  active: Scalars['Boolean']
  allowCashOrders: Scalars['Boolean']
  canStampBeEarnedToday: Maybe<Scalars['Boolean']>
  discount: Discount
  discountStrategy: LoyaltyCardDiscountStrategy
  id: Scalars['ID']
  loyaltyCardColor: Scalars['String']
  loyaltyCardIcon: Scalars['String']
  loyaltyCardStamps: Array<LoyaltyCardStamp>
  netTotal: Maybe<Scalars['Int']>
  requiredStamps: Scalars['Int']
  termsAndConditions: Scalars['String']
}

export enum LoyaltyCardDiscountStrategy {
  BASKET_SUBTOTAL_DISCOUNT = 'BASKET_SUBTOTAL_DISCOUNT',
  WHOLE_ORDER_DISCOUNT = 'WHOLE_ORDER_DISCOUNT',
}

export type LoyaltyCardStamp = {
  __typename: 'LoyaltyCardStamp'
  amountEarnedOnOrder: Scalars['Int']
  customer: Customer
  id: Scalars['ID']
  loyaltyCard: LoyaltyCard
  order: Order
  stampClaimedOnOrder: Maybe<Order>
}

export type LoyaltyCardStampOrderInfo = {
  __typename: 'LoyaltyCardStampOrderInfo'
  amountEarnedOnOrder: Scalars['Int']
  loyaltyCardIcon: Scalars['String']
  loyaltyCardName: Scalars['String']
  requiredStamps: Scalars['Int']
  stampsEarnedUpToThisOrder: Scalars['Int']
  totalStampsText: Scalars['String']
}

/** This will be the response then the payment.paymentMethod is MANUAL_CONFIRMATION_CARD. */
export type ManualConfirmationCardOrderResponse = {
  __typename: 'ManualConfirmationCardOrderResponse'
  ephemeralKeySecret: Scalars['String']
  orders: Array<Order>
  /** This is used to find the payment intent on the client, and "confirm" the payment. */
  paymentIntentClientSecret: Scalars['String']
}

export type Marketplace = {
  __typename: 'Marketplace'
  ageRestrictionText: Maybe<Scalars['String']>
  allOutletsText: Maybe<Scalars['String']>
  allowExtendedPreorders: Maybe<Scalars['Boolean']>
  allowLocationSearch: Scalars['Boolean']
  allowMenuItemMainImageV5: Scalars['Boolean']
  allowMenuItemOptionImageV5: Scalars['Boolean']
  allowOnboarding: Scalars['Boolean']
  allowOrderToTable: Scalars['Boolean']
  allowReorder: Scalars['Boolean']
  allowReviews: Maybe<Scalars['Boolean']>
  allowSearch: Scalars['Boolean']
  allowSingleItemOrderNotes: Maybe<Scalars['Boolean']>
  androidVersion: Scalars['String']
  appImage: Maybe<Scalars['String']>
  appServiceComponentIcon: Scalars['String']
  appText: Maybe<Scalars['String']>
  appTextSecondary: Maybe<Scalars['String']>
  /** List of approved non-cash payment methods from the payment provider for this marketplace. null enables all payment methods setup in the payment provider. */
  approvedPaymentProviderMethods: Maybe<Array<Scalars['String']>>
  brandColor: Maybe<Scalars['String']>
  brandImage: Maybe<Scalars['Boolean']>
  brandSecondaryColor: Maybe<Scalars['String']>
  brandTertiaryColor: Maybe<Scalars['String']>
  cashCollectionText: Scalars['String']
  cashText: Scalars['String']
  categories: Array<Cuisine>
  categoryEmojiOrImage: CategoryEmojiOrImage
  checkoutText: Maybe<Scalars['String']>
  cname: Maybe<Scalars['String']>
  collectionNoteText: Maybe<Scalars['String']>
  companyLegalName: Maybe<Scalars['String']>
  companyNumber: Maybe<Scalars['String']>
  confirmAddressAtPayment: Scalars['Boolean']
  contactAddress: Address
  contactEmail: Scalars['String']
  contactName: Scalars['String']
  contactPhone: Scalars['String']
  country: Country
  cuisines: Array<Cuisine>
  defaultDatepickerOption: DefaultDatepickerOption
  defaultFulfilmentOption: DefaultFulfilmentOption
  defaultLocationOption: DefaultLocationOption
  defaultSearch: Maybe<SearchMethod>
  deliveryMinimumOrderValue: Maybe<Scalars['Int']>
  deliveryNoteText: Maybe<Scalars['String']>
  deliveryZones: Array<DeliveryZone>
  enableCustomerV2Client: Scalars['Boolean']
  enableRedirectFromLandingPage: Maybe<Scalars['Boolean']>
  facebookAdAccountId: Maybe<Scalars['String']>
  facebookAudienceId: Maybe<Scalars['String']>
  facebookPixelId: Scalars['String']
  faqs: Array<FAQPartial>
  faviconImage: Maybe<Scalars['String']>
  featureFAQ: Maybe<Scalars['Boolean']>
  featureLogin: Scalars['Boolean']
  featureOrdering: Scalars['Boolean']
  featurePromotions: Maybe<Scalars['Boolean']>
  featureRecruit: Maybe<Scalars['Boolean']>
  featureRegister: Scalars['Boolean']
  footerLinks: Array<CustomLinkFooter>
  ga4Id: Scalars['String']
  headerLinks: Array<CustomLinkHeader>
  heroImage: Maybe<Scalars['String']>
  heroText: Maybe<Scalars['String']>
  heroTextSecondary: Maybe<Scalars['String']>
  hideCategoriesListOnMobile: Scalars['Boolean']
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  iosVersion: Scalars['String']
  isVATregistered: Scalars['Boolean']
  key: Scalars['String']
  kioskAllowSearch: Scalars['Boolean']
  kioskMenuListItemStyle: MenuListItemStyle
  kioskOptionsListStyle: OptionsListStyle
  legalAllergy: Maybe<Scalars['String']>
  legalCookies: Maybe<Scalars['String']>
  legalPolicy: Maybe<Scalars['String']>
  legalPrivacy: Maybe<Scalars['String']>
  legalTerms: Maybe<Scalars['String']>
  logoImage: Maybe<Scalars['String']>
  menuListGroupStyle: MenuListGroupStyle
  menuListItemStyle: MenuListItemStyle
  merchantType: MerchantType
  metaDescription: Maybe<Scalars['String']>
  metaKeywords: Maybe<Scalars['String']>
  minAppVersionSupported: Scalars['String']
  name: Scalars['String']
  onBehalfOfStripeId: Maybe<Scalars['String']>
  onboardingDescription: Maybe<Scalars['String']>
  optionsListStyle: OptionsListStyle
  orderMode: OrderMode
  orderNoteText: Maybe<Scalars['String']>
  outletOpenStatus: OutletOpenStatus
  partnerCharge: Maybe<Scalars['Int']>
  partnerChargeDescription: Maybe<Scalars['String']>
  partnerTableCharge: Maybe<Scalars['Int']>
  partnerTableChargeDescription: Maybe<Scalars['String']>
  promptForUpdates: Scalars['Boolean']
  socialURLFacebook: Maybe<Scalars['String']>
  socialURLInstagram: Maybe<Scalars['String']>
  socialURLTikTok: Maybe<Scalars['String']>
  socialURLTwitter: Maybe<Scalars['String']>
  specialInstructionsText: Maybe<Scalars['String']>
  storeURLApple: Maybe<Scalars['String']>
  storeURLGooglePlay: Maybe<Scalars['String']>
  stripeAccountId: Maybe<Scalars['String']>
  stripePublicId: Maybe<Scalars['String']>
  supportEmail: Maybe<Scalars['String']>
  supportPhone: Maybe<Scalars['String']>
  tableNoteText: Maybe<Scalars['String']>
  theme: Theme
  title: Maybe<Scalars['String']>
  trackingId: Maybe<Scalars['String']>
  urlPath: Scalars['String']
  vatNumber: Maybe<Scalars['String']>
  websiteCnameCertificateArn: Maybe<Scalars['String']>
}

export type MarketplacestripePublicIdArgs = {
  useRedboxKey?: InputMaybe<Scalars['Boolean']>
}

export type MenuItem = MenuItemInterface & {
  __typename: 'MenuItem'
  SKU: Maybe<Scalars['String']>
  VATinclusive: Maybe<Scalars['Boolean']>
  addOnMaxPrice: Maybe<Scalars['Int']>
  ageRestricted: Maybe<Scalars['Boolean']>
  allergens: Maybe<Array<Allergen>>
  archived: Scalars['Boolean']
  bulkyItem: Scalars['Boolean']
  categoryTag: Maybe<Scalars['String']>
  costPrice: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  isDairyFree: Maybe<Scalars['Boolean']>
  isGlutenFree: Maybe<Scalars['Boolean']>
  isKeto: Scalars['Boolean']
  isPromoted: Maybe<Scalars['Boolean']>
  isVegan: Maybe<Scalars['Boolean']>
  isVegetarian: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  maxPurchaseQuantity: Maybe<Scalars['Int']>
  minimumPurchasePrice: Maybe<Scalars['Int']>
  name: Scalars['String']
  optionPositions: Array<Scalars['String']>
  options: Array<Option>
  outletHidden: Maybe<Scalars['Boolean']>
  outletSoldOut: Maybe<Scalars['Boolean']>
  parentMenus: Array<MenuItemGroup>
  position: Maybe<Scalars['Int']>
  price: Scalars['Int']
  soldOut: Maybe<Scalars['Boolean']>
  spiceLevel: Scalars['Int']
  vatRate: Maybe<Scalars['Float']>
}

export type MenuItemGroup = MenuItemGroupInterface & {
  __typename: 'MenuItemGroup'
  addOnItemsMenu: Scalars['Boolean']
  availabilityEndDate: Maybe<Scalars['DateTime']>
  availabilityStartDate: Maybe<Scalars['DateTime']>
  availabilityTimes: Maybe<Scalars['JSON']>
  availabilityTimesArray: Array<AvailabilityTime>
  description: Maybe<Scalars['String']>
  featuredImage: Maybe<Scalars['String']>
  fulfillmentMethods: Array<Scalars['String']>
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  menuItems: Array<MenuItem>
  name: Scalars['String']
  narrowFulfilmentMethods: Array<NarrowFulfilmentMethodInputType>
  parentMenu: Maybe<MenuItemGroup>
  position: Maybe<Scalars['Int']>
  showMenuThumbnails: Maybe<Scalars['Boolean']>
}

export type MenuItemGroupmenuItemsArgs = {
  showInactive?: InputMaybe<Scalars['Boolean']>
}

export enum MenuItemGroupFulfillmentMethod {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  TABLE = 'TABLE',
}

export type MenuItemGroupInterface = {
  addOnItemsMenu: Scalars['Boolean']
  availabilityEndDate: Maybe<Scalars['DateTime']>
  availabilityStartDate: Maybe<Scalars['DateTime']>
  availabilityTimes: Maybe<Scalars['JSON']>
  description: Maybe<Scalars['String']>
  fulfillmentMethods: Array<Scalars['String']>
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  name: Scalars['String']
  narrowFulfilmentMethods: Array<NarrowFulfilmentMethodInputType>
  position: Maybe<Scalars['Int']>
  showMenuThumbnails: Maybe<Scalars['Boolean']>
}

export type MenuItemGroupSnapshot = {
  __typename: 'MenuItemGroupSnapshot'
  description: Maybe<Scalars['String']>
  fulfillmentMethods: Array<Scalars['String']>
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  name: Scalars['String']
  position: Maybe<Scalars['Int']>
  showMenuThumbnails: Maybe<Scalars['Boolean']>
}

export type MenuItemInterface = {
  SKU: Maybe<Scalars['String']>
  VATinclusive: Maybe<Scalars['Boolean']>
  addOnMaxPrice: Maybe<Scalars['Int']>
  ageRestricted: Maybe<Scalars['Boolean']>
  archived: Scalars['Boolean']
  bulkyItem: Scalars['Boolean']
  categoryTag: Maybe<Scalars['String']>
  costPrice: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  isDairyFree: Maybe<Scalars['Boolean']>
  isGlutenFree: Maybe<Scalars['Boolean']>
  isKeto: Scalars['Boolean']
  isPromoted: Maybe<Scalars['Boolean']>
  isVegan: Maybe<Scalars['Boolean']>
  isVegetarian: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  maxPurchaseQuantity: Maybe<Scalars['Int']>
  minimumPurchasePrice: Maybe<Scalars['Int']>
  name: Scalars['String']
  optionPositions: Array<Scalars['String']>
  outletHidden: Maybe<Scalars['Boolean']>
  outletSoldOut: Maybe<Scalars['Boolean']>
  position: Maybe<Scalars['Int']>
  price: Scalars['Int']
  soldOut: Maybe<Scalars['Boolean']>
  spiceLevel: Scalars['Int']
  vatRate: Maybe<Scalars['Float']>
}

export type MenuItemSnapshot = {
  __typename: 'MenuItemSnapshot'
  SKU: Maybe<Scalars['String']>
  UPC: Maybe<Scalars['String']>
  VATinclusive: Maybe<Scalars['Boolean']>
  ageRestricted: Maybe<Scalars['Boolean']>
  archived: Maybe<Scalars['Boolean']>
  bulkyItem: Maybe<Scalars['Boolean']>
  categoryTag: Maybe<Scalars['String']>
  costPrice: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  hidden: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['ID']>
  image: Maybe<Scalars['String']>
  isDairyFree: Maybe<Scalars['Boolean']>
  isGlutenFree: Maybe<Scalars['Boolean']>
  isKeto: Maybe<Scalars['Boolean']>
  isPromoted: Maybe<Scalars['Boolean']>
  isVegan: Maybe<Scalars['Boolean']>
  isVegetarian: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  minimumPurchasePrice: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  optionPositions: Maybe<Array<Scalars['String']>>
  outletHidden: Maybe<Scalars['Boolean']>
  outletSoldOut: Maybe<Scalars['Boolean']>
  position: Maybe<Scalars['Int']>
  price: Maybe<Scalars['Int']>
  soldOut: Maybe<Scalars['Boolean']>
  spiceLevel: Maybe<Scalars['Int']>
  vatRate: Maybe<Scalars['Float']>
}

export enum MenuListGroupStyle {
  IMAGE_GRID = 'IMAGE_GRID',
  LIST = 'LIST',
}

export enum MenuListItemStyle {
  IMAGE_GRID = 'IMAGE_GRID',
  IMAGE_LIST = 'IMAGE_LIST',
  LIST = 'LIST',
}

export type MerchantCategory = {
  __typename: 'MerchantCategory'
  id: Scalars['ID']
  isDefault: Scalars['Boolean']
  plural: Scalars['String']
  singular: Scalars['String']
}

export enum MerchantType {
  RESTAURANT = 'RESTAURANT',
  RETAIL = 'RETAIL',
}

export type Mutation = {
  __typename: 'Mutation'
  acceptDiscount: Discount
  addCard: AddCustomerCard
  archiveCustomer: ArchiveCustomerResponse
  changePassword: ChangePasswordResponse
  createAddress: CreateAddressResponse
  createOrder: CreateOrderResponse
  customerSupportEmail: Scalars['Boolean']
  deleteAccount: Scalars['Boolean']
  deleteAddress: ArchiveAddressResponse
  deleteCard: DeleteCustomerCard
  editAddress: EditAddressResponse
  editContactDetails: EditContactDetails
  editDevicePushToken: Scalars['Boolean']
  kioskLogin: KioskLoginSuccess
  login: LoginSuccess
  onboardingApply: OnboardingReturnType
  /** @deprecated Use CreateOrder instead */
  orderToTable: Order
  ping: Scalars['String']
  register: CustomerRegistrationResponse
  registerVisitor: Array<Visitor>
  requestResetPassword: PasswordRequest
  resetPassword: ResetPassword
  updateMarketingPreferences: Customer
}

export type MutationacceptDiscountArgs = {
  enrolmentKey: Scalars['String']
}

export type MutationaddCardArgs = {
  token: Scalars['String']
}

export type MutationchangePasswordArgs = {
  currentPassword?: InputMaybe<Scalars['String']>
  newPassword?: InputMaybe<Scalars['String']>
  newPasswordConfirm?: InputMaybe<Scalars['String']>
}

export type MutationcreateAddressArgs = {
  input: CreateAddressInput
}

export type MutationcreateOrderArgs = {
  orderData: CreateOrderInput
}

export type MutationcustomerSupportEmailArgs = {
  emailAddress: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  marketplaceId: Scalars['String']
  message: Scalars['String']
  orderNumber?: InputMaybe<Scalars['String']>
  phoneNumber: Scalars['String']
  subject: Scalars['String']
}

export type MutationdeleteAccountArgs = {
  password: Scalars['String']
}

export type MutationdeleteAddressArgs = {
  addressId?: InputMaybe<Scalars['String']>
}

export type MutationdeleteCardArgs = {
  cardId: Scalars['String']
}

export type MutationeditAddressArgs = {
  input: EditAddressInput
}

export type MutationeditContactDetailsArgs = {
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
}

export type MutationeditDevicePushTokenArgs = {
  data?: InputMaybe<EditDevicePushTokenInput>
}

export type MutationkioskLoginArgs = {
  credentials?: InputMaybe<KioskLoginInput>
}

export type MutationloginArgs = {
  login?: InputMaybe<LoginInput>
}

export type MutationonboardingApplyArgs = {
  contactAddress: AddressCreateInput
  contactEmail: Scalars['String']
  contactName: Scalars['String']
  contactPhone: Scalars['String']
  marketplaceKey: Scalars['String']
  name: Scalars['String']
}

export type MutationorderToTableArgs = {
  orderData: orderToTableInput
}

export type MutationregisterArgs = {
  email: Scalars['String']
  enrolmentKey?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  phoneNumber: Scalars['String']
}

export type MutationregisterVisitorArgs = {
  visitors?: InputMaybe<Array<VisitorInput>>
}

export type MutationrequestResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
}

export type MutationresetPasswordArgs = {
  customerId?: InputMaybe<Scalars['String']>
  newPassword?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
}

export type MutationupdateMarketingPreferencesArgs = {
  optOutMarketingEmail?: InputMaybe<Scalars['Boolean']>
  optOutMarketingPush?: InputMaybe<Scalars['Boolean']>
  optOutMarketingSMS?: InputMaybe<Scalars['Boolean']>
  optOutMenuMarketing?: InputMaybe<Scalars['Boolean']>
  optOutNewRestaurantMarketing?: InputMaybe<Scalars['Boolean']>
  optOutPromotionMarketing?: InputMaybe<Scalars['Boolean']>
}

export enum NarrowFulfilmentMethodInputType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  TABLE = 'TABLE',
}

export type OnboardingReturnType = {
  __typename: 'OnboardingReturnType'
  message: Scalars['String']
}

export type OpeningTime = {
  __typename: 'OpeningTime'
  end: DayAndTime
  id: Scalars['ID']
  start: DayAndTime
  timeSlot: Scalars['Int']
}

export type OpeningTimeTranslation = {
  __typename: 'OpeningTimeTranslation'
  datetime: Maybe<Scalars['DateTime']>
  datetimeFormat: Maybe<Scalars['String']>
  days: Maybe<Scalars['String']>
  key: Scalars['String']
}

export type Option = {
  __typename: 'Option'
  archived: Maybe<Scalars['Boolean']>
  hidden: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  maxOptions: Scalars['Int']
  minOptions: Scalars['Int']
  name: Scalars['String']
  optionItems: Array<OptionItem>
  position: Maybe<Scalars['Int']>
}

export type OptionItem = {
  __typename: 'OptionItem'
  VATinclusive: Maybe<Scalars['Boolean']>
  ageRestricted: Maybe<Scalars['Boolean']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  isDairyFree: Maybe<Scalars['Boolean']>
  isGlutenFree: Maybe<Scalars['Boolean']>
  isKeto: Maybe<Scalars['Boolean']>
  isVegan: Maybe<Scalars['Boolean']>
  isVegetarian: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  parentOption: Option
  position: Maybe<Scalars['Int']>
  price: Scalars['Int']
  soldOut: Maybe<Scalars['Boolean']>
  spiceLevel: Scalars['Int']
  vatRate: Maybe<Scalars['Float']>
}

export enum OptionsListStyle {
  LIST = 'LIST',
  WIZARD = 'WIZARD',
  WIZARD_IMAGE = 'WIZARD_IMAGE',
}

export type Order = {
  __typename: 'Order'
  asap: Maybe<Scalars['Boolean']>
  cancellationNotes: Maybe<Scalars['String']>
  cleanOrderStatus: CleanOrderStatus
  createdAt: Scalars['DateTime']
  customer: Maybe<Customer>
  customerBillingAddress: Maybe<Address>
  customerDeliveryAddress: Maybe<Address>
  customerDeliveryNotes: Maybe<Scalars['String']>
  customerOrderNotes: Maybe<Scalars['String']>
  customerTaxes: Scalars['Int']
  deliveryNetworkJobTrackerURL: Maybe<Scalars['String']>
  deliveryNotes: Maybe<Scalars['String']>
  deliveryWindow: Maybe<DeliveryWindow>
  detailedPaymentMethod: DetailedPaymentMethod
  discount: Maybe<Scalars['JSON']>
  discountAmount: Maybe<Scalars['Int']>
  endOfPrep: Maybe<Scalars['DateTime']>
  estimatedCompletionTime: Maybe<Scalars['DateTime']>
  estimatedDeliveryDate: Maybe<Scalars['DateTime']>
  friendlyOrderNumber: Maybe<Scalars['Int']>
  fulfillmentCharge: Maybe<Scalars['Int']>
  fulfillmentChargeNet: Maybe<Scalars['Int']>
  fulfillmentMethod: OrderFulfillmentMethods
  grossTotal: Scalars['Int']
  groupedItems: Array<GroupedOrderItem>
  id: Scalars['ID']
  key: Maybe<Scalars['String']>
  loyaltyCardStampInfo: Array<LoyaltyCardStampOrderInfo>
  narrowFulfilmentMethod: NarrowFulfilmentMethodInputType
  netTotal: Scalars['Int']
  orderItems: Array<OrderItem>
  orderNumber: Scalars['String']
  orderStatus: Maybe<OrderStatus>
  outlet: Outlet
  outletId: Maybe<Scalars['String']>
  outletNotes: Maybe<Scalars['String']>
  partnerCharge: Maybe<Scalars['Int']>
  payment: OrderPaymentResponse
  /** @deprecated Use the paymentIntentClientSecret on the createOrder mutation response instead */
  paymentIntentClientSecret: Maybe<Scalars['String']>
  paymentMethod: PaymentMethod
  platformNotes: Maybe<Scalars['String']>
  requireAction: Scalars['Boolean']
  selectedDeliverySlot: Maybe<Scalars['DateTime']>
  /** @deprecated selectedDeliveryWindow is deprecated. Use deliveryWindow instead. */
  selectedDeliveryWindow: Maybe<Scalars['JSON']>
  subtotalGross: Maybe<Scalars['Int']>
  subtotalNet: Maybe<Scalars['Int']>
  tableId: Maybe<Scalars['String']>
  tableSnapshot: Maybe<Scalars['JSON']>
  updatedAt: Scalars['DateTime']
}

export type OrderConnection = {
  __typename: 'OrderConnection'
  edges: Array<OrderEdge>
  pageInfo: OrderPageInfo
  totalCount: Scalars['Int']
}

export type OrderEdge = {
  __typename: 'OrderEdge'
  cursor: Scalars['String']
  node: Order
}

export enum OrderFulfillmentMethods {
  COLLECTION = 'COLLECTION',
  COURIER = 'COURIER',
  DELIVERY = 'DELIVERY',
  NETWORK = 'NETWORK',
  TABLE = 'TABLE',
}

export type OrderItem = {
  __typename: 'OrderItem'
  createdAt: Scalars['DateTime']
  customerLabel: Maybe<Scalars['String']>
  grossTotal: Maybe<Scalars['Int']>
  id: Scalars['ID']
  isAddOnItem: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  menuItem: Maybe<Scalars['JSON']>
  menuItemGroup: Maybe<Scalars['JSON']>
  menuItemGroupSnapshot: Maybe<MenuItemGroupSnapshot>
  menuItemSnapshot: Maybe<MenuItemSnapshot>
  netTotal: Maybe<Scalars['Int']>
  optionItems: Maybe<Array<Scalars['JSON']>>
  singleItemNotes: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type OrderItemInput = {
  optionItemIds?: InputMaybe<Array<Scalars['String']>>
  outletMenuItemId: Scalars['String']
  price?: InputMaybe<Scalars['Int']>
  singleItemNotes?: InputMaybe<Scalars['String']>
}

export enum OrderMode {
  LIST = 'LIST',
  POSTCODE = 'POSTCODE',
  SINGLE = 'SINGLE',
}

export type OrderOptionItem = {
  __typename: 'OrderOptionItem'
  VATinclusive: Maybe<Scalars['Boolean']>
  ageRestricted: Maybe<Scalars['Boolean']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  isDairyFree: Maybe<Scalars['Boolean']>
  isGlutenFree: Maybe<Scalars['Boolean']>
  isKeto: Maybe<Scalars['Boolean']>
  isVegan: Maybe<Scalars['Boolean']>
  isVegetarian: Maybe<Scalars['Boolean']>
  name: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  price: Maybe<Scalars['Int']>
  soldOut: Maybe<Scalars['Boolean']>
  spiceLevel: Maybe<Scalars['Int']>
  vatRate: Maybe<Scalars['Float']>
}

export type OrderPageInfo = {
  __typename: 'OrderPageInfo'
  endCursor: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor: Maybe<Scalars['String']>
}

/** Payment details for the order */
export type OrderPaymentResponse =
  | CardPayment
  | CashPayment
  | InPersonCardPayment
  | OtherPayment

export enum OrderStatus {
  AUTH_ACTION_FAILURE = 'AUTH_ACTION_FAILURE',
  AUTH_ACTION_PENDING = 'AUTH_ACTION_PENDING',
  AWAITING_DELIVERY_NETWORK = 'AWAITING_DELIVERY_NETWORK',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  COMPLETE = 'COMPLETE',
  DELIVERY_FAILURE_REFUND = 'DELIVERY_FAILURE_REFUND',
  DELIVERY_NETWORK_FAILURE = 'DELIVERY_NETWORK_FAILURE',
  ORDER_FAILURE_REFUND = 'ORDER_FAILURE_REFUND',
  PENDING = 'PENDING',
  PREPARING = 'PREPARING',
  READY = 'READY',
  REFUND_CANCELLED = 'REFUND_CANCELLED',
  REFUND_FAILED = 'REFUND_FAILED',
  REFUND_RELEASED = 'REFUND_RELEASED',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REJECTED = 'REJECTED',
}

export type OtherPayment = {
  __typename: 'OtherPayment'
  errorMessage: Maybe<Scalars['String']>
  paymentMethodName: Scalars['String']
}

export type Outlet = {
  __typename: 'Outlet'
  /**
   * The amount of time in minutes it takes to deliver an ASAP order.
   * The Cache-Control header max-age is set to the next time we expect the asap delivery duration to change.
   * Returns null if the outlet if it is not possible to deliver ASAP (eg if the outlet is closed).
   */
  ASAPDeliveryDuration: Maybe<Scalars['Int']>
  activationDate: Maybe<Scalars['DateTime']>
  active: Maybe<Scalars['Boolean']>
  allowCollection: Scalars['Boolean']
  allowPreorders: Scalars['Boolean']
  asapAllowed: Scalars['Boolean']
  asapCollectionAllowed: Scalars['Boolean']
  asapDeliveryAllowed: Scalars['Boolean']
  availableFulfillmentMethods: Array<FulfillmentMethodType>
  availableFulfilmentInputMethods: Array<NarrowFulfilmentMethodInputType>
  availableFulfilmentTimeTypes: Array<FulfilmentTimeType>
  availableFulfilments: Array<AvailableFulfilment>
  closeDate: Maybe<Scalars['DateTime']>
  closedUntil: Maybe<Scalars['DateTime']>
  collectionCharge: Maybe<Scalars['Int']>
  collectionFulfilmentTimes: Maybe<FulfilmentTime>
  collectionMinimumOrderValue: Maybe<Scalars['Int']>
  collectionPreorderTimes: Array<Scalars['DateTime']>
  coverImage: Maybe<Scalars['String']>
  daysOfferedInAdvanceMax: Scalars['Int']
  daysOfferedInAdvanceMaxCollection: Scalars['Int']
  daysOfferedInAdvanceMaxDelivery: Scalars['Int']
  daysOfferedInAdvanceMin: Scalars['Int']
  daysOfferedInAdvanceMinCollection: Scalars['Int']
  daysOfferedInAdvanceMinDelivery: Scalars['Int']
  /** @deprecated Use prepTime instead, which is not nullable, and defaults to 25 minutes */
  defaultCollectionTime: Maybe<Scalars['Int']>
  defaultDeliveryTime: Scalars['Int']
  deliveryFulfilmentTimes: Maybe<FulfilmentTime>
  deliveryMinimumOrderValue: Maybe<Scalars['Int']>
  deliveryPreorderWindows: Array<DeliveryPreorderWindow>
  deliveryZones: Array<DeliveryZone>
  description: Maybe<Scalars['String']>
  displayName: Scalars['String']
  distanceFromUserKM: Maybe<Scalars['Float']>
  enableHygieneRating: Scalars['Boolean']
  featured: Scalars['Boolean']
  /** Returns a string representing either the duration (if priority fulfilment is delivery) or distance (if priority fulfilment is collection / table) between the fulfilment location and the outlet. */
  fulfilmentRange: Maybe<Scalars['String']>
  fulfilmentTimeTypes: Array<FulfilmentTimeType>
  /** @deprecated use deliveryPreorderWindows / collectionPreorderTimes instead, as their return types are more specific */
  fulfilmentTimes: Array<FulfilmentTime>
  hiddenMenuItemGroupIds: Array<Scalars['String']>
  hiddenMenuItemIds: Array<Scalars['String']>
  hygieneRatingId: Maybe<Scalars['Int']>
  id: Scalars['ID']
  isOnline: Scalars['Boolean']
  isOpen: Scalars['Boolean']
  isOrderToTableEnabled: Scalars['Boolean']
  isOrderable: Scalars['Boolean']
  isPendingTableNumbersEnabled: Maybe<Scalars['Boolean']>
  isVATregistered: Scalars['Boolean']
  marketplace: Marketplace
  menuItemGroups: Array<MenuItemGroup>
  name: Scalars['String']
  nextOpenDate: Scalars['String']
  nextOpeningTime: Scalars['String']
  noPreordersBeforeOpening: Scalars['Boolean']
  /** OutletNowFilter flag from marketplace */
  nowFilter: OutletNowFilter
  openingTimeString: Scalars['String']
  openingTimes: Maybe<Scalars['JSON']>
  openingTimesArray: Array<OpeningTime>
  outletAddress: Address
  /** Returns discounts that are allowed for that outlet. */
  outletAllDiscounts: Array<Discount>
  outletCuisines: Array<Cuisine>
  outletEmail: Maybe<Scalars['String']>
  outletLogoOverride: Maybe<Scalars['String']>
  /** Returns loyalty cards available for this outlet */
  outletLoyaltyCards: Array<LoyaltyCard>
  outletPaymentMethodsForFulfilmentType: Array<DetailedPaymentMethod>
  outletPhone: Maybe<Scalars['String']>
  partnerCharge: Scalars['Int']
  partnerTableCharge: Scalars['Int']
  paymentMethods: Array<Scalars['String']>
  pendingTableNumbersCustomText: Maybe<Scalars['String']>
  preorderCollectionAllowed: Scalars['Boolean']
  preorderDeliveryAllowed: Scalars['Boolean']
  preorderStatus: Scalars['String']
  prepTime: Scalars['Int']
  /**
   *
   *         Returns the prioritised fulfilment methods for the outlet, based on what the outlet offers.
   *         eg [Delivery, Collection, Table] if the outlet offers all 3
   *         If `acceptedFulfilmentMethods` is provided, then the array only includes fulfilment methods in that array
   *         If `input` is provided, then the array only includes fulfilment methods available for the given location
   *
   */
  prioritisedFulfilmentMethods: Array<NarrowFulfilmentMethodInputType>
  promoteOutlet: Scalars['Boolean']
  restaurant: Restaurant
  soldOutMenuItemIds: Array<Scalars['String']>
  soldOutUntilItems: Maybe<Scalars['JSON']>
  specialOfferFlags: SpecialOfferFlags
  /**
   *
   *         Returns the current status text of the outlet.
   *         If the client already has a specific fulfilment method selected, then this can be passed as `fulfilmentMethod` to get the status text for that method.
   *         If the server should return the status text for a server-calculate priority fulfilment method then the client can either pass:
   *           * no args:
   *               The server will return status text for the priority fulfilment method of the outlet
   *               regardless of the fulfilment methods selected by the client / available to the customer location.
   *           * fulfilmentLocation:
   *               The server will return status text for the priority fulfilment method available to that location.
   *           * acceptedFulfilmentMethods:
   *               The server will return status text for the priority fulfilment method, from the array of accepted fulfilment methods (defaults to all)
   *         Note: fulfilmentChosen is deprecated and should not be used - for existing apps which pass it, it functions the same as fulfilmentMethod.
   *
   */
  statusText: OutletStatusText
  tableFulfilmentTimes: Maybe<FulfilmentTime>
  tables: Array<Table>
}

export type OutletavailableFulfilmentTimeTypesArgs = {
  fulfilmentMethod: FulfillmentMethodType
}

export type OutletcollectionPreorderTimesArgs = {
  from?: InputMaybe<Scalars['DateTime']>
  to?: InputMaybe<Scalars['DateTime']>
}

export type OutletdeliveryPreorderWindowsArgs = {
  from?: InputMaybe<Scalars['DateTime']>
  returnFullWindows?: InputMaybe<Scalars['Boolean']>
  to?: InputMaybe<Scalars['DateTime']>
}

export type OutletfulfilmentRangeArgs = {
  acceptedFulfilmentMethods?: InputMaybe<Array<NarrowFulfilmentMethodInputType>>
  fulfilmentLocation?: InputMaybe<FulfilmentLocationInput>
  fulfilmentMethod?: InputMaybe<NarrowFulfilmentMethodInputType>
}

export type OutletfulfilmentTimesArgs = {
  from?: InputMaybe<Scalars['DateTime']>
  fulfilmentMethod: FulfillmentMethodType
  limit?: InputMaybe<Scalars['Int']>
  returnFullWindows?: InputMaybe<Scalars['Boolean']>
  to?: InputMaybe<Scalars['DateTime']>
}

export type OutletisOrderableArgs = {
  acceptedFulfilmentMethods?: InputMaybe<Array<NarrowFulfilmentMethodInputType>>
  fulfilmentLocation?: InputMaybe<FulfilmentLocationInput>
  fulfilmentMethod?: InputMaybe<NarrowFulfilmentMethodInputType>
}

export type OutletmenuItemGroupsArgs = {
  endOfPrep?: InputMaybe<Scalars['DateTime']>
  fulfillmentMethods: Array<MenuItemGroupFulfillmentMethod>
}

export type OutletoutletAllDiscountsArgs = {
  fulfilmentMethod: NarrowFulfilmentMethodInputType
}

export type OutletoutletLoyaltyCardsArgs = {
  getMarketplaceWideOnes?: InputMaybe<Scalars['Boolean']>
}

export type OutletoutletPaymentMethodsForFulfilmentTypeArgs = {
  fulfilmentMethod?: InputMaybe<FulfilmentMethodInputType>
  narrowFulfilmentMethod?: InputMaybe<NarrowFulfilmentMethodInputType>
}

export type OutletprioritisedFulfilmentMethodsArgs = {
  acceptedFulfilmentMethods?: InputMaybe<Array<NarrowFulfilmentMethodInputType>>
  fulfilmentLocation?: InputMaybe<FulfilmentLocationInput>
}

export type OutletstatusTextArgs = {
  acceptedFulfilmentMethods?: InputMaybe<Array<NarrowFulfilmentMethodInputType>>
  fulfilmentChosen?: InputMaybe<FulfilmentMethodInputType>
  fulfilmentLocation?: InputMaybe<FulfilmentLocationInput>
  fulfilmentMethod?: InputMaybe<NarrowFulfilmentMethodInputType>
  fulfilmentTimeType?: InputMaybe<FulfilmentTimeType>
}

export type OutletConnection = {
  __typename: 'OutletConnection'
  edges: Array<OutletEdge>
  pageInfo: OutletPageInfo
  totalCount: Scalars['Int']
}

export type OutletCountByFulfillmentType = {
  __typename: 'OutletCountByFulfillmentType'
  COLLECTION: Scalars['Int']
  DELIVERY: Scalars['Int']
  TABLE: Scalars['Int']
}

export type OutletEdge = {
  __typename: 'OutletEdge'
  cursor: Scalars['String']
  node: Outlet
}

export type OutletMenuItem = MenuItemInterface & {
  __typename: 'OutletMenuItem'
  SKU: Maybe<Scalars['String']>
  VATinclusive: Maybe<Scalars['Boolean']>
  addOnMaxPrice: Maybe<Scalars['Int']>
  ageRestricted: Maybe<Scalars['Boolean']>
  allergens: Maybe<Array<Allergen>>
  archived: Scalars['Boolean']
  bulkyItem: Scalars['Boolean']
  categoryTag: Maybe<Scalars['String']>
  costPrice: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  featuredImages: Array<FeaturedImage>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  isDairyFree: Maybe<Scalars['Boolean']>
  isGlutenFree: Maybe<Scalars['Boolean']>
  isKeto: Scalars['Boolean']
  isPromoted: Maybe<Scalars['Boolean']>
  isVegan: Maybe<Scalars['Boolean']>
  isVegetarian: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  maxPurchaseQuantity: Maybe<Scalars['Int']>
  menuItemId: Scalars['String']
  minimumPurchasePrice: Maybe<Scalars['Int']>
  name: Scalars['String']
  optionPositions: Array<Scalars['String']>
  options: Array<Option>
  outlet: Maybe<Outlet>
  outletHidden: Maybe<Scalars['Boolean']>
  outletSoldOut: Maybe<Scalars['Boolean']>
  parentMenu: MenuItemGroup
  position: Maybe<Scalars['Int']>
  price: Scalars['Int']
  soldOut: Maybe<Scalars['Boolean']>
  spiceLevel: Scalars['Int']
  vatRate: Maybe<Scalars['Float']>
}

export type OutletMenuItemGroup = MenuItemGroupInterface & {
  __typename: 'OutletMenuItemGroup'
  addOnItemsMenu: Scalars['Boolean']
  availabilityEndDate: Maybe<Scalars['DateTime']>
  availabilityStartDate: Maybe<Scalars['DateTime']>
  availabilityTimes: Maybe<Scalars['JSON']>
  availabilityTimesArray: Array<AvailabilityTime>
  description: Maybe<Scalars['String']>
  featuredImage: Maybe<Scalars['String']>
  fulfillmentMethods: Array<Scalars['String']>
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  menuItemGroupId: Scalars['String']
  /** @deprecated Please use outletMenuItems instead it is the exact same data but with the correct type */
  menuItems: Array<MenuItem>
  name: Scalars['String']
  narrowFulfilmentMethods: Array<NarrowFulfilmentMethodInputType>
  outletMenuItems: Array<OutletMenuItem>
  parentMenu: Maybe<MenuItemGroup>
  position: Maybe<Scalars['Int']>
  showMenuThumbnails: Maybe<Scalars['Boolean']>
}

export type OutletMenuItemGroupmenuItemsArgs = {
  showInactive?: InputMaybe<Scalars['Boolean']>
}

export type OutletMenuItemGroupoutletMenuItemsArgs = {
  showInactive?: InputMaybe<Scalars['Boolean']>
}

export enum OutletNowFilter {
  CURRENTLY_OPEN = 'CURRENTLY_OPEN',
  TODAY = 'TODAY',
}

export enum OutletOpenStatus {
  OPEN_PREORDER_CLOSED = 'OPEN_PREORDER_CLOSED',
  ORDER_PREORDER_VIEW = 'ORDER_PREORDER_VIEW',
}

export type OutletPageInfo = {
  __typename: 'OutletPageInfo'
  endCursor: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor: Maybe<Scalars['String']>
}

export type OutletStatusText = {
  __typename: 'OutletStatusText'
  openingTimeTranslation: OpeningTimeTranslation
  orderButtonTranslation: Scalars['String']
}

/**
 * Validation strictly asserts input must match one of these schemas:
 *
 *     ```
 *     | {
 *       locationType: EVERYWHERE
 *       marketplaceId: String
 *       marketplaceKey: String
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: DELIVERY_ZONE
 *       deliveryZoneId: String!
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: POSTCODE
 *       marketplaceId: String
 *       marketplaceKey: String
 *       postAndCountryCode: { postcode: 'LS12 2DS', countryCode: 'UK' }
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: ADDRESS
 *       marketplaceId: String
 *       marketplaceKey: String
 *       addressId: String!
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: COORDINATES
 *       marketplaceId: String
 *       marketplaceKey: String
 *       coordinates: {
 *         lat: Float!
 *         lng: Float!
 *       },
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     ```
 *
 */
export type OutletsInput = {
  addressId?: InputMaybe<Scalars['String']>
  coordinates?: InputMaybe<CoordinatesInput>
  cuisineIds?: InputMaybe<Array<Scalars['String']>>
  deliveryZoneId?: InputMaybe<Scalars['String']>
  featured?: InputMaybe<Scalars['Boolean']>
  fulfilmentMethods?: InputMaybe<Array<OrderFulfillmentMethods>>
  fulfilmentTimeTypes?: InputMaybe<Array<InputMaybe<FulfilmentTimeType>>>
  locationType: LocationType
  marketplaceId?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
  narrowFulfilmentMethods?: InputMaybe<Array<NarrowFulfilmentMethodInputType>>
  postAndCountryCode?: InputMaybe<PostAndCountryCodeInput>
  selectedDate?: InputMaybe<Scalars['DateTime']>
  whenType?: InputMaybe<FulfilmentFilterWhenType>
}

export type Partner = {
  __typename: 'Partner'
  allowSubBrands: Maybe<Scalars['Boolean']>
  archived: Scalars['Boolean']
  brandColor: Maybe<Scalars['String']>
  brandImage: Maybe<Scalars['Boolean']>
  cname: Maybe<Scalars['String']>
  contactEmail: Scalars['String']
  contactName: Scalars['String']
  contactPhone: Scalars['String']
  id: Scalars['ID']
  isVATregistered: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  name: Scalars['String']
  orderHoldInterval: Maybe<Scalars['Int']>
  partnerVat: Maybe<Scalars['Int']>
  platformCharge: Maybe<Scalars['Int']>
  platformFee: Maybe<Scalars['Float']>
  stripeId: Maybe<Scalars['String']>
  vatNumber: Maybe<Scalars['String']>
}

export type PasswordRequest = {
  __typename: 'PasswordRequest'
  message: Scalars['String']
}

/**
 * Validation strictly asserts input matches one of these schemas:
 *
 *     ```
 *     | {
 *       paymentMethod: CASH
 *     }
 *     | {
 *       paymentMethod: CARD
 *       cardPaymentToken?: String
 *     }
 *     | {
 *       paymentMethod: MANUAL_CONFIRMATION_CARD
 *       saveCard?: Boolean
 *     }
 *     | {
 *       paymentMethod: CARD_IN_PERSON
 *     }
 *     | {
 *       paymentMethod: WALLET
 *     }
 *     ```
 *
 */
export type PaymentInput = {
  cardPaymentToken?: InputMaybe<Scalars['String']>
  paymentMethod: DetailedPaymentMethod
  saveCard?: InputMaybe<Scalars['Boolean']>
}

export enum PaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
}

export type PostAndCountryCodeInput = {
  countryCode?: InputMaybe<Scalars['String']>
  postcode: Scalars['String']
}

export type PreorderDeliveryWindowInput = {
  end: Scalars['DateTime']
  start: Scalars['DateTime']
}

export enum PushTokenType {
  APN = 'APN',
  FCM = 'FCM',
}

export type Query = {
  __typename: 'Query'
  addOnMenuItems: Array<AddOnItem>
  addressById: Address
  allDiscountsByOutletId: Array<Discount>
  basketItems: Array<BasketItem>
  businessByCname: Restaurant
  businessSegment: BusinessSegment
  businessSegmentOutlets: Array<Outlet>
  businessSegments: Array<BusinessSegment>
  checkoutBasketTotals: CheckoutTotalsV2
  cnameLookup: CnameUnion
  collectionCheckoutTotals: CheckoutTotals
  customerDetails: Customer
  customerDiscountForBusiness: Maybe<Discount>
  deliveryEstimateForAddress: DeliveryCost
  deliveryEstimateForCoordinates: DeliveryCost
  deliveryEstimateForPostcode: DeliveryCost
  deliveryEstimateForZone: DeliveryCost
  /** @deprecated use checkoutBasketTotals instead. */
  deliveryToAddressCheckoutTotals: CheckoutTotals
  /** @deprecated use checkoutBasketTotals instead. */
  deliveryToCoordinatesCheckoutTotals: CheckoutTotals
  /** @deprecated use checkoutBasketTotals instead. */
  deliveryToPostcodeCheckoutTotals: CheckoutTotals
  /** @deprecated use checkoutBasketTotals instead. */
  deliveryToZoneCheckoutTotals: CheckoutTotals
  deliveryZonesByMarketplaceId: Array<DeliveryZone>
  featuredOutlets: Array<Outlet>
  featuredOutletsForLocation: Array<Outlet>
  getCardClientSecret: Scalars['String']
  /** @deprecated Has been replaced by orders query which provides GraphQL spec pagination and accepts a pagination input object, please migrate to the new query ASAP */
  getMyOrders: Array<Order>
  isVoucherValid: Discount
  kioskOutlets: Array<Outlet>
  marketplace: Marketplace
  marketplaceByAuth: Marketplace
  marketplaceByCname: Marketplace
  marketplaceById: Marketplace
  marketplaceByOutletId: Marketplace
  menuItemGroupsForOutlet: Array<OutletMenuItemGroup>
  menuItemTextSearch: Array<OutletMenuItem>
  myOrder: Order
  /** @deprecated Has been replaced by orders query */
  myOrders: OrderConnection
  optionItemById: OptionItem
  optionItemsById: Array<OptionItem>
  optionsByOutletMenuItemId: Array<Option>
  order: Order
  orders: OrderConnection
  outlet: Maybe<Outlet>
  outletByIdWithDistance: Maybe<Outlet>
  outletCountByFulfilmentType: OutletCountByFulfillmentType
  outletMenuItem: OutletMenuItem
  outletMenuItems: Array<OutletMenuItem>
  outlets: Array<Outlet>
  outletsForCoordinates: Array<Outlet>
  outletsForDeliveryZone: Array<Outlet>
  outletsForPostcode: Array<Outlet>
  outletsOfferingCollection: Array<Outlet>
  outletsOfferingOrderToTable: Array<Outlet>
  paginatedMenuItems: Array<MenuItem>
  payboxTerminal: Scalars['String']
  ping: Scalars['String']
  restaurantByOutletId: Restaurant
  restaurantsForDeliveryZone: Array<Restaurant>
  searchOutletsAndCategoriesByName: Array<SearchResponse>
  singleLoyaltyCard: LoyaltyCard
  singleMenuItemGroupForOutlet: Array<OutletMenuItemGroup>
  /** @deprecated use checkoutBasketTotals instead. */
  tableCheckoutTotals: CheckoutTotals
  tables: Array<Table>
  traceOutlet: Maybe<Outlet>
}

export type QueryaddOnMenuItemsArgs = {
  basketTotal?: InputMaybe<Scalars['Int']>
  endOfPrep?: InputMaybe<Scalars['DateTime']>
  fulfilmentMethods: Array<MenuItemGroupFulfillmentMethod>
  outletId: Scalars['ID']
  subTotal?: InputMaybe<Scalars['Int']>
}

export type QueryaddressByIdArgs = {
  addressId: Scalars['ID']
}

export type QueryallDiscountsByOutletIdArgs = {
  fulfilmentMethod: NarrowFulfilmentMethodInputType
  outletId: Scalars['ID']
}

export type QuerybusinessByCnameArgs = {
  cname: Scalars['String']
}

export type QuerybusinessSegmentArgs = {
  id: Scalars['String']
}

export type QuerybusinessSegmentOutletsArgs = {
  input: BusinessSegmentOutletsInput
}

export type QuerybusinessSegmentsArgs = {
  businessSegmentTypes?: InputMaybe<Array<BusinessSegmentType>>
  marketplaceId: Scalars['String']
  selectedDate?: InputMaybe<Scalars['String']>
}

export type QuerycheckoutBasketTotalsArgs = {
  basketData: CheckoutBasketTotalsInput
}

export type QuerycnameLookupArgs = {
  cname: Scalars['String']
}

export type QuerycollectionCheckoutTotalsArgs = {
  appliedDiscountId?: InputMaybe<Scalars['String']>
  basketSubtotal: Scalars['Int']
  outletId: Scalars['String']
}

export type QuerycustomerDiscountForBusinessArgs = {
  outletId: Scalars['String']
}

export type QuerydeliveryEstimateForAddressArgs = {
  addressId: Scalars['String']
  outletId: Scalars['String']
  preorderFor?: InputMaybe<Scalars['DateTime']>
}

export type QuerydeliveryEstimateForCoordinatesArgs = {
  coordinates: CoordinatesInput
  outletId: Scalars['String']
}

export type QuerydeliveryEstimateForPostcodeArgs = {
  outletId: Scalars['String']
  postcode: Scalars['String']
}

export type QuerydeliveryEstimateForZoneArgs = {
  deliveryZoneId: Scalars['String']
  outletId: Scalars['String']
}

export type QuerydeliveryToAddressCheckoutTotalsArgs = {
  appliedDiscountId?: InputMaybe<Scalars['String']>
  basketSubtotal: Scalars['Int']
  deliveryAddressId: Scalars['String']
  outletId: Scalars['String']
  preorderFor?: InputMaybe<Scalars['DateTime']>
}

export type QuerydeliveryToCoordinatesCheckoutTotalsArgs = {
  appliedDiscountId?: InputMaybe<Scalars['String']>
  basketSubtotal: Scalars['Int']
  coordinates: CoordinatesInput
  outletId: Scalars['String']
}

export type QuerydeliveryToPostcodeCheckoutTotalsArgs = {
  appliedDiscountId?: InputMaybe<Scalars['String']>
  basketSubtotal: Scalars['Int']
  deliveryPostcode: Scalars['String']
  outletId: Scalars['String']
}

export type QuerydeliveryToZoneCheckoutTotalsArgs = {
  appliedDiscountId?: InputMaybe<Scalars['String']>
  basketSubtotal: Scalars['Int']
  deliveryZoneId: Scalars['String']
  outletId: Scalars['String']
}

export type QuerydeliveryZonesByMarketplaceIdArgs = {
  marketplaceId: Scalars['ID']
}

export type QueryfeaturedOutletsArgs = {
  marketplaceId?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
}

export type QueryfeaturedOutletsForLocationArgs = {
  deliveryZoneId?: InputMaybe<Scalars['String']>
  marketplaceId?: InputMaybe<Scalars['String']>
  postAndCountryCode?: InputMaybe<PostAndCountryCodeInput>
  selectedDate?: InputMaybe<Scalars['DateTime']>
}

export type QuerygetMyOrdersArgs = {
  count: Scalars['Int']
}

export type QueryisVoucherValidArgs = {
  key: Scalars['String']
  marketplaceId: Scalars['String']
  outletId?: InputMaybe<Scalars['String']>
}

export type QuerymarketplaceArgs = {
  key: Scalars['String']
}

export type QuerymarketplaceByCnameArgs = {
  cname: Scalars['String']
}

export type QuerymarketplaceByIdArgs = {
  marketplaceId: Scalars['ID']
}

export type QuerymarketplaceByOutletIdArgs = {
  outletId: Scalars['ID']
}

export type QuerymenuItemGroupsForOutletArgs = {
  endOfPrep?: InputMaybe<Scalars['DateTime']>
  fulfillmentMethods?: InputMaybe<Array<MenuItemGroupFulfillmentMethod>>
  getUnavailableBySchedule?: InputMaybe<Scalars['Boolean']>
  narrowFulfilmentMethods?: InputMaybe<Array<NarrowFulfilmentMethodInputType>>
  outletId: Scalars['ID']
  showInactive?: InputMaybe<Scalars['Boolean']>
}

export type QuerymenuItemTextSearchArgs = {
  includeDescription?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  outletId: Scalars['ID']
  searchQuery: Scalars['String']
}

export type QuerymyOrderArgs = {
  id: Scalars['String']
}

export type QuerymyOrdersArgs = {
  input: ConnectionInputObject
}

export type QueryoptionItemByIdArgs = {
  optionItemId: Scalars['ID']
}

export type QueryoptionItemsByIdArgs = {
  optionItemIds: Array<Scalars['ID']>
}

export type QueryoptionsByOutletMenuItemIdArgs = {
  outletMenuItemId: Scalars['String']
}

export type QueryorderArgs = {
  id: Scalars['String']
}

export type QueryordersArgs = {
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  input: ConnectionInputObject
}

export type QueryoutletArgs = {
  id: Scalars['String']
  showInactive?: InputMaybe<Scalars['Boolean']>
}

export type QueryoutletByIdWithDistanceArgs = {
  coordinates?: InputMaybe<CoordinatesInput>
  id: Scalars['String']
  postAndCountryCode?: InputMaybe<PostAndCountryCodeInput>
}

export type QueryoutletCountByFulfilmentTypeArgs = {
  deliveryZoneId?: InputMaybe<Scalars['ID']>
  marketplaceId: Scalars['ID']
}

export type QueryoutletMenuItemArgs = {
  menuItemId: Scalars['ID']
  outletId: Scalars['ID']
}

export type QueryoutletMenuItemsArgs = {
  endOfPrep?: InputMaybe<Scalars['DateTime']>
  getUnavailableBySchedule?: InputMaybe<Scalars['Boolean']>
  outletMenuItemIds?: InputMaybe<Array<Scalars['ID']>>
}

export type QueryoutletsArgs = {
  input: OutletsInput
}

export type QueryoutletsForCoordinatesArgs = {
  coordinates: CoordinatesInput
  cuisineIds?: InputMaybe<Array<Scalars['String']>>
  marketplaceId?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
}

export type QueryoutletsForDeliveryZoneArgs = {
  cuisineIds?: InputMaybe<Array<Scalars['String']>>
  deliveryZoneId: Scalars['String']
  selectedDate?: InputMaybe<Scalars['DateTime']>
}

export type QueryoutletsForPostcodeArgs = {
  cuisineIds?: InputMaybe<Array<Scalars['String']>>
  marketplaceId?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
  postAndCountryCode: PostAndCountryCodeInput
  selectedDate?: InputMaybe<Scalars['DateTime']>
}

export type QueryoutletsOfferingCollectionArgs = {
  coordinates?: InputMaybe<CoordinatesInput>
  cuisineIds?: InputMaybe<Array<Scalars['String']>>
  deliveryZoneId?: InputMaybe<Scalars['String']>
  marketplaceId?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
  postAndCountryCode?: InputMaybe<PostAndCountryCodeInput>
}

export type QueryoutletsOfferingOrderToTableArgs = {
  coordinates: CoordinatesInput
  cuisineIds?: InputMaybe<Array<Scalars['String']>>
  marketplaceId?: InputMaybe<Scalars['String']>
  marketplaceKey?: InputMaybe<Scalars['String']>
}

export type QuerypaginatedMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  fulfillmentMethods?: InputMaybe<Array<MenuItemGroupFulfillmentMethod>>
  menuItemGroupIds?: InputMaybe<Array<Scalars['ID']>>
  outletId: Scalars['ID']
  pageSize?: InputMaybe<Scalars['Int']>
  showInactive?: InputMaybe<Scalars['Boolean']>
}

export type QueryrestaurantByOutletIdArgs = {
  outletId: Scalars['ID']
}

export type QueryrestaurantsForDeliveryZoneArgs = {
  cuisineIds?: InputMaybe<Array<Scalars['ID']>>
  deliveryZoneId: Scalars['ID']
}

export type QuerysearchOutletsAndCategoriesByNameArgs = {
  marketplaceId: Scalars['String']
  nameContains: Scalars['String']
}

export type QuerysingleLoyaltyCardArgs = {
  id: Scalars['String']
}

export type QuerysingleMenuItemGroupForOutletArgs = {
  menuItemGroupId: Scalars['ID']
  narrowFulfilmentMethods: Array<NarrowFulfilmentMethodInputType>
  outletId: Scalars['ID']
}

export type QuerytableCheckoutTotalsArgs = {
  appliedDiscountId?: InputMaybe<Scalars['String']>
  basketSubtotal: Scalars['Int']
  outletId: Scalars['String']
}

export type QuerytablesArgs = {
  outletId: Scalars['String']
}

export type QuerytraceOutletArgs = {
  id: Scalars['String']
}

export type ResetPassword = {
  __typename: 'ResetPassword'
  customer: Customer
  message: Scalars['String']
  token: Scalars['String']
}

export type Restaurant = {
  __typename: 'Restaurant'
  acceptPreorders: Maybe<Scalars['Boolean']>
  allDiscounts: Array<Discount>
  allowAddOnItems: Maybe<Scalars['Boolean']>
  cname: Maybe<Scalars['String']>
  collectionDeal: Maybe<Scalars['String']>
  contactEmail: Scalars['String']
  contactName: Scalars['String']
  contactPhone: Scalars['String']
  cuisines: Array<Cuisine>
  deals: Maybe<Scalars['Boolean']>
  dealsText: Maybe<Scalars['String']>
  deliveryDeal: Maybe<Scalars['String']>
  deliveryOptions: Array<Scalars['String']>
  description: Maybe<Scalars['String']>
  discounts: Array<Discount>
  displayAllOutlets: Maybe<Scalars['Boolean']>
  enableAllergyInformation: Maybe<Scalars['Boolean']>
  enableStackedMenu: Maybe<Scalars['Boolean']>
  faviconImage: Maybe<Scalars['String']>
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  metaDescription: Maybe<Scalars['String']>
  metaKeywords: Maybe<Scalars['String']>
  name: Scalars['String']
  outletOfferFlagOverride: Maybe<Scalars['Boolean']>
  outletPromoteOverride: Maybe<Scalars['Boolean']>
  outlets: Array<Outlet>
  partnerCharge: Maybe<Scalars['Int']>
  partnerTableCharge: Maybe<Scalars['Int']>
  /** @deprecated Please use outlet.promoteOutlet instead, which determines the value based on outletPromoteOverride. */
  promoted: Scalars['Boolean']
  tableDeal: Maybe<Scalars['String']>
}

export type RestaurantallDiscountsArgs = {
  fulfilmentMethod: NarrowFulfilmentMethodInputType
}

export enum RestauratStatusOnboarding {
  DENIED = 'DENIED',
  ONBOARDING = 'ONBOARDING',
  SIGNUP = 'SIGNUP',
}

export enum SearchMethod {
  COORDINATES = 'COORDINATES',
  LIST = 'LIST',
  POSTCODE = 'POSTCODE',
}

/** Union of the objects that can be returned from a search */
export type SearchResponse = Cuisine | Outlet

export enum SegmentOrderBy {
  ALPHABETICAL = 'ALPHABETICAL',
  DISTANCE = 'DISTANCE',
  MIN_ORDER = 'MIN_ORDER',
  OPEN_STATUS = 'OPEN_STATUS',
  POSITION = 'POSITION',
  RANDOM = 'RANDOM',
  TIME = 'TIME',
}

export type SpecialOfferFlags = {
  __typename: 'SpecialOfferFlags'
  collectionDeal: Maybe<Scalars['String']>
  deliveryDeal: Maybe<Scalars['String']>
  tableDeal: Maybe<Scalars['String']>
}

export type Table = {
  __typename: 'Table'
  friendlyName: Scalars['String']
  id: Scalars['ID']
}

export type Theme = {
  __typename: 'Theme'
  colors: ThemeColors
  id: Scalars['String']
}

export type ThemeColors = {
  __typename: 'ThemeColors'
  brand: Scalars['String']
  brandDark: Scalars['String']
  brandForeground: Scalars['String']
  brandHighlight: Scalars['String']
  brandLight: Scalars['String']
  danger: Scalars['String']
  darkGrey: Scalars['String']
  darkText: Scalars['String']
  discount: Scalars['String']
  flag: Scalars['String']
  grey: Scalars['String']
  mainText: Scalars['String']
  openStatus: Scalars['String']
  preorderStatus: Scalars['String']
  siteBackground: Scalars['String']
}

export type UpdateMarketingPreferencesInput = {
  optOutMarketingEmail?: InputMaybe<Scalars['Boolean']>
  optOutMarketingPush?: InputMaybe<Scalars['Boolean']>
  optOutMarketingSMS?: InputMaybe<Scalars['Boolean']>
  optOutMenuMarketing?: InputMaybe<Scalars['Boolean']>
  optOutNewRestaurantMarketing?: InputMaybe<Scalars['Boolean']>
  optOutPromotionMarketing?: InputMaybe<Scalars['Boolean']>
}

export type Visitor = {
  __typename: 'Visitor'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name: Scalars['String']
  phoneNumber: Scalars['String']
}

export type VisitorInput = {
  name: Scalars['String']
  outletId: Scalars['String']
  phoneNumber: Scalars['String']
}

export type Voucher = {
  __typename: 'Voucher'
  active: Scalars['Boolean']
  customerIds: Maybe<Array<Scalars['String']>>
  discount: Discount
  id: Scalars['ID']
  key: Scalars['String']
  marketplace: Marketplace
  onePerCustomer: Scalars['Boolean']
  usageCount: Maybe<Scalars['Int']>
  usageLimit: Maybe<Scalars['Int']>
}

export type menuItemWithOptionsInput = {
  addOnItem?: InputMaybe<Scalars['Boolean']>
  menuItemId: Scalars['String']
  optionItemIds?: InputMaybe<Array<Scalars['String']>>
  price?: InputMaybe<Scalars['Int']>
  singleItemNotes?: InputMaybe<Scalars['String']>
}

export type orderToTableInput = {
  customerDeliveryNotes?: InputMaybe<Scalars['String']>
  customerOrderNotes?: InputMaybe<Scalars['String']>
  orderItems: Array<menuItemWithOptionsInput>
  outletId: Scalars['String']
  paymentToken?: InputMaybe<Scalars['String']>
  tableId: Scalars['String']
}
